import React, { useState } from 'react'
import packageJson from '../../../package.json'
import { useDispatch, useSelector } from 'react-redux'
import { Menu, Visibility, VisibilityOff } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Tooltip,
  Typography,
  IconButton,
  Button,
  Avatar
} from '@material-ui/core'
//LOCAL
import { useAllStyles } from 'ln_style'
import { useSafety } from 'ln_util/hooks'
import { hub_actions } from 'ln_core/rootReducer'
const { toggleMenu, login } = hub_actions

const localStyle = ({ spacing, breakpoints: { down } }) => ({
  avatar: {
    width: '32px',
    height: '32px',
    [down('sm')]: { marginRight: spacing(2) }
  },
  userName: {
    fontSize: spacing(2),
    margin: '0 0.5em',
    color: 'white',
    marginRight: spacing(3),
    [down('sm')]: { display: 'none' }
  }
})

const RootAppBar = () => {
  const c = useAllStyles(localStyle)
  const dp = useDispatch()
  const {
    user: { name, imageUrl },
    menu
  } = useSelector(({ hub }) => hub)
  const [toggle, safe, r_safe] = useSafety(login)
  const [hover, $hover] = useState(false)

  const handleMenuClick = ev => dp(toggleMenu())

  return (
    <>
      <div className={c.grow}>
        <AppBar position='static'>
          <Toolbar>
            <Tooltip title={menu ? 'Hide Menu' : 'Show Menu'}>
              <IconButton
                edge='start'
                className={c.mr1}
                color='inherit'
                aria-label='menu'
                onClick={handleMenuClick}
                onMouseEnter={() => $hover(true)}
                onMouseLeave={() => $hover(false)}
              >
                {hover ? menu ? <VisibilityOff /> : <Visibility /> : <Menu />}
              </IconButton>
            </Tooltip>
            <Typography variant='h6' className={c.grow}>
              <Link to='/home'>{`TFL Portal v.${packageJson.version}`}</Link>
            </Typography>
            <Avatar className={c.avatar} alt='user avatar' src={imageUrl} />
            <Typography className={c.userName}>{name}</Typography>
            <Button
              variant='contained'
              color={safe ? 'secondary' : 'default'}
              size='small'
              onClick={() => toggle({ logout: true })}
              onMouseOut={r_safe}
            >
              logout{safe && '?'}
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    </>
  )
}

export default RootAppBar
