import React from 'react'
import { Grid } from '@material-ui/core'
//LOCAL
import { useAllStyles } from 'ln_style'
import { DateField } from 'ln_ui'

const Timeline = ({ value, onChange }) => {
  const c = useAllStyles()

  const update = (k, v) => {
    const output = { ...value, [k]: v }
    onChange(output)
  }

  const props = key => ({
    className: c.formField,
    value: value[key],
    onChange: value => update(key, value)
  })

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <DateField label='Estimated Start Date' {...props('startDate')} />
      </Grid>
       <Grid item xs={12} md={6}>
        <DateField label='Estimated End Date' {...props('endDate')} />
       </Grid>
    </Grid>
  )
}

export { Timeline }
