import React from 'react'
import {
  Button,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core'

const ConfirmDialog = ({ confirm, closeDialog, warning }) => {
  const buttonProps = {
    type: 'button',
    color: 'secondary',
    disableRipple: true
  }

  return (
    <>
      <DialogTitle>
        Are you sure?
      </DialogTitle >
      <DialogContent>
        {warning}
      </DialogContent >
      <DialogActions >
        <Button {...buttonProps} onClick={confirm} variant='contained'>
          Yes
        </Button>
        <Button {...buttonProps} onClick={closeDialog} variant='outlined'>
          No
        </Button>
      </DialogActions >
    </>
  )
}

export default ConfirmDialog
