import React from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
//LOCAL
import { FormButtons } from 'ln_ui'
import { useAllStyles } from 'ln_style'
import { genCall, genTimestamp } from 'ln_util/functions'

const localStyles = ({ spacing }) => ({
  buttons: {
    margin: 'auto',
    marginRight: spacing(2)
  }
})

const Controls = ({ week, $message }) => {
  const c = useAllStyles(localStyles)
  const list = useSelector(({ forms }) => forms.time)
  const { email } = useSelector(({ hub }) => hub.user)
  const stamp = { _timestamp: genTimestamp(), _email: email }

  const handleSubmit = () => {
    // const [url, opts] = genCall('submit/time', 'JSON')
    const [url, opts] = genCall('new/time', 'JSON')
    const formData = list.map(
      ({ workOrderNumber, notes, formData, ...rest }) => ({
        ...rest,
        notes: `${notes || ''}${!!workOrderNumber ? ` WO:${workOrderNumber}` : ''}`
      })
    )
    const data = { formData, week, stamp }
    axios
      .post(url, data, opts)
      .then(({ data: { status, message } }) => {
        $message(message)
      })
      .catch(console.log)
  }

  return (
    <FormButtons
      className={c.buttons}
      submit={handleSubmit}
      slice='time'
      valid={list.length}
    ></FormButtons>
  )
}

export { Controls }
