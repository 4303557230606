import React from 'react'
//LOCAL
import { RowActions } from './rowActions'
import { numSort } from 'ln_util/functions'

const columns = ({ history, admin }) => {
  const defaultColumns = [
    {
      field: 'jobNumber',
      title: 'Job Number',
      defaultSort: 'desc',
      customSort: numSort
    },
    {
      field: 'details.name',
      title: 'Job Name'
    },
    {
      field: 'details.customer',
      title: 'Customer'
    },
    {
      field: 'details.salesman',
      title: 'Salesman'
    },
    {
      field: 'address.street',
      title: 'Address',
      render: rowData => {
        const address = !!rowData.address
          ? `${rowData.address.street} ${rowData.address.city}, ${rowData.address.state} ${rowData.address.zip}`
          : ''
        return <>{address}</>
      }
    }
  ]

  return admin.includes('/jobs')
    ? [
        ...defaultColumns,
        {
          title: 'Actions',
          render: rowData => <RowActions {...{ rowData, history }} />
        }
      ]
    : defaultColumns
}
export { columns }
