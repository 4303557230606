import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField, Checkbox, InputAdornment } from '@material-ui/core'
import {
  ExpandMore,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank
} from '@material-ui/icons'
//LOCAL
import { useAllStyles } from 'ln_style'

const localStyle = () => ({ input: { padding: 0, cursor: 'pointer' } })

const ComboBox = ({
  options,
  value,
  onChange,
  notListedOpt = false,
  multiSelect = false,
  creatable = false,
  textFieldProps = { label: false, required: false },
  ...rest
}) => {
  const currOption = options.find(opt => opt.value === value) || multiSelect
  const c = useAllStyles(localStyle)
  return (
    <Autocomplete
      //default
      {...rest}
      autoHighlight
      clearOnEscape
      clearText={''}
      options={options}
      freeSolo={creatable}
      loading={!options.length}
      classes={{ inputRoot: c.input }}
      //value & onChange handlers
      getOptionLabel={({ label }) => label || ''}
      inputValue={multiSelect ? '' : !!currOption ? currOption.label : value}
      onInputChange={(ev, value) => {
        if (!!ev && ev.type !== 'blur') {
          const isNotanOpt = !options.find(opt => opt.label === value)
          if (isNotanOpt && !multiSelect) onChange(value)
        }
      }}
      onChange={(ev, opt) => {
        if (!!opt) {
          multiSelect ? onChange(opt) : !!opt.value && onChange(opt.value)
        }
      }}
      onBlur={() => {
        if (!creatable && !currOption && !multiSelect) onChange('')
      }}
      //multiSelect
      multiple={multiSelect}
      disableClearable={!multiSelect}
      disableCloseOnSelect={multiSelect}
      //not listed option
      filterOptions={(options, { inputValue }) =>
        options.filter(option => {
          if (option.label === notListedOpt) return true
          return option.label.toLowerCase().includes(inputValue.toLowerCase())
        })
      }
      //render options
      renderOption={(option, { selected }) => (
        <React.Fragment>
          {multiSelect && (
            <Checkbox
              icon={<CheckBoxOutlineBlank />}
              checkedIcon={<CheckBoxIcon />}
              className={c.mr1}
              checked={selected}
            />
          )}
          {option.label}
        </React.Fragment>
      )}
      //render textbox
      renderInput={props => (
        <TextField
          {...{ ...props, ...textFieldProps }}
          fullWidth
          InputLabelProps={{ shrink: true }}
          error={!currOption && !creatable && !!value}
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position='end'>
                <ExpandMore />
              </InputAdornment>
            )
          }}
        />
      )}
    />
  )
}

export { ComboBox }
