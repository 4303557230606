import React from 'react'
//LOCAL
import { genEditComponent } from 'ln_ui/table'
import { APISelect, ListSelect } from 'ln_ui'
import { dateSort, humanDate } from 'ln_util/functions'

const columns = [
  {
    field: 'date',
    title: 'Date',
    defaultSort: 'desc',
    customSort: dateSort,
    editComponent: genEditComponent('date'),
    render: rowData => <>{humanDate(rowData.date)}</>
  },
  {
    field: 'poNumber',
    title: 'Number'
  },
  {
    field: 'supplier',
    title: 'Supplier',
    editComponent: props => <ListSelect list='supplier' {...props} />
  },
  {
    field: 'matCat',
    title: 'Category',
    editComponent: props => <ListSelect list='material_category' {...props} />
  },
  {
    field: 'job',
    title: 'Job',
    editComponent: props => <APISelect collection='jobs' {...props} />
  },
  {
    field: 'notes',
    title: 'Notes',
    editComponent: genEditComponent()
  }
]

export { columns }
