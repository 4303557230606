import React from 'react'
// LOCAL
import { genInputProps } from 'ln_util/functions'
import { opts } from 'ln_util/constants'
import {ComboBox} from './comboBox'

const ListSelect = ({ list, required = false, withLabel = false, ...rest }) => {
  const options = opts[list]
  const { label } = genInputProps(list)
  const props = {
    ...rest,
    options,
    textFieldProps: withLabel ? { label, required } : { required }
  }
  return <ComboBox {...props} />
}

export { ListSelect }
