import React, { useState } from 'react'
import { Drawer, Icon, IconButton } from '@material-ui/core'

const InfoButton = props => {
  return (
    <IconButton {...props}>
      <Icon className='fas fa-info-circle' />
    </IconButton>
  )
}

const InfoDrawer = ({ children, disabled }) => {
  const [open, $open] = useState(false)
  const openInfo = () => $open(true)
  const closeInfo = () => $open(false)

  return (
    <>
      <InfoButton disabled={disabled} onClick={openInfo} style={{ marginRight: 8 }} />
      <Drawer anchor='right' open={open} onClose={closeInfo}>
        {children}
      </Drawer>
    </>
  )
}

export { InfoDrawer }
