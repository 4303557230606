import React, { useState } from 'react'
import axios from 'axios'
import { IconButton, Tooltip, Dialog } from '@material-ui/core'
import { Folder, AttachFile, CheckCircle } from '@material-ui/icons'
//LOCAL
import asanaIcon from './asanaIcon.png'
import { genCall } from 'ln_util/functions'
import { useAllStyles } from 'ln_style'
import { ConfirmDialog } from 'ln_ui'

const localStyle = ({ spacing }) => ({
  iconButton: { margin: spacing(0, 0.5) }
})

const RowActions = ({ rowData, history }) => {
  const c = useAllStyles(localStyle)
  const [dialog, $dialog] = useState(false)
  const closeDialog = () => $dialog(false)
  const openDialog = () => $dialog(true)
  const warning = `${rowData.jobNumber} ${rowData.details.name} will no longer be listed to apply time or purchase orders to; The Asana task will be completed and the job folder moved.`
  const openFolder = () =>
    window
      .open(
        `https://drive.google.com/drive/u/0/folders/${rowData.meta.folderId}`,
        '_blank'
      )
      .focus()
  const openTasks = () =>
    window
      .open(
        `https://app.asana.com/0/1113463476249760/${rowData.meta.gid}/f`,
        '_blank'
      )
      .focus()
  const uploadDoc = () => history.push(`/jobs?jobDoc=${rowData.hid}`)
  const [url, opts] = genCall('complete/job', 'JSON')
  const closeJob = () =>
    axios
      .post(url, rowData, opts)
      // .then(({ data }) => {
      //   $message(data)
      //   dp(form_actions.job_clear())
      // })
      .catch(console.log)
  return (
    <>
      <Dialog open={dialog}>
        <ConfirmDialog closeDialog={closeDialog} confirm={closeJob} warning={warning}/>
      </Dialog>
      <Tooltip title='Upload Document'>
        <IconButton onClick={uploadDoc} className={c.iconButton} size='small'>
          <AttachFile />
        </IconButton>
      </Tooltip>
      {rowData.meta.folderId && (
        <Tooltip title='Open Folder'>
          <IconButton
            onClick={openFolder}
            className={c.iconButton}
            size='small'
          >
            <Folder />
          </IconButton>
        </Tooltip>
      )}
      {rowData.meta.gid && (
        <Tooltip title='Open Tasks'>
          <IconButton onClick={openTasks} className={c.iconButton} size='small'>
            <img alt='Asana' src={asanaIcon} width={20} style={{ margin: 2 }} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title='Close Job'>
        <IconButton onClick={openDialog} className={c.iconButton} size='small'>
          <CheckCircle />
        </IconButton>
      </Tooltip>
    </>
  )
}

export { RowActions }
