//NUMBER HELPERS
const isNumber = n => !isNaN(n * 1)

const formatMoney = n =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    n
  )

const limitDecimal = (n, places) => {
  let dotPos = n.toString().lastIndexOf('.')
  return dotPos === -1 || dotPos + places + 2 > n.length
}

const numSort = (a, b) => +a.jobNumber - +b.jobNumber

export { isNumber, formatMoney, limitDecimal, numSort }
