//MISC HELPERS
import { genTimestamp } from './time.fn'

const makeList = label => ({ label, value: label })

const objoin = (...obs) => obs.reduce((ac, ob) => ({ ...ac, ...ob }), {})

const columnMap = (key, lookup) => col =>
  col.field === key ? { ...col, lookup } : col

const cladd = (...classNames) => classNames.join(' ')

const reducer = (state, update) => ({ ...state, ...update })

const reduck = (state, { name, value }) => {
  const recurve = (subState, [head, ...tail]) =>
    reducer(subState, {
      [head]: !!tail.length ? recurve(subState[head], tail) : value
    })
  return !!name ? recurve(state, name.split('.')) : value
}

const stamp = email => ({ _timestamp: genTimestamp(), _email: email })

export { makeList, objoin, columnMap, cladd, reduck, stamp }
