import { combineReducers } from 'redux'
import genActions, { genReducer } from 'ln_core/fns'
import hub_types, { init as hub_init, toggleMenu, login } from './actions/hub'

import { inits } from './init'
const [lookup_actions, lookup_types] = genActions(inits.lookup, 'lookups')
const [form_actions, form_types] = genActions(inits.forms, 'forms')
const [dashboard_actions, dashboard_types] = genActions(inits.dashboards,'dashboards')

const hub_actions = { toggleMenu, login }

const reducers = {
  hub: genReducer(hub_types, hub_init),
  forms: genReducer(form_types, inits.forms),
  lookups: genReducer(lookup_types, inits.lookup),
  dashboards: genReducer(dashboard_types, inits.dashboards)
}

export default combineReducers(reducers)

export {
  lookup_actions,
  form_actions,
  hub_actions,
  dashboard_actions
}
