import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import CheckBox from '@material-ui/icons/CheckBox'
//LOCAL
import { genCall, columnMap, stamp } from 'ln_util/functions'
import { useLookup, useDashboard } from 'ln_util/hooks'
import { icons } from 'ln_ui/table'
import { columns } from './columns'

const Table = ({ $message, $form }) => {
  const inspections = useDashboard({
    key: 'inspections',
    query: '?completed=false'
  })
  const contractCustomers = useLookup({ collection: 'contract_customers' })
  const { email, admin } = useSelector(({ hub }) => hub.user)
  const [cols, $cols] = useState(columns)
  const hasPerms = admin.includes('/inspections')

  useEffect(() => {
    if (!!contractCustomers) {
      const updatedColumns = cols.map(
        columnMap('contractCustomer', contractCustomers)
      )
      $cols(updatedColumns)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractCustomers])

  const options = {
    selection: hasPerms,
    pageSize: 20,
    pageSizeOptions: [10, 20, 30],
    grouping: true,
    addRowPosition: 'first',
    actionsColumnIndex: -1,
    search: true,
    showSelectAllCheckbox: false
  }

  const actions = hasPerms
    ? [
        {
          tooltip: 'Complete',
          icon: CheckBox,
          onClick: (evt, data) => {
            $form(
              data.map(({ tableData, date, ...rest }) => ({
                date: new Date(date).toISOString(),
                ...rest
              }))
            )
          }
        }
      ]
    : []

  const editable = hasPerms
    ? {
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            const { date, contractCustomer, iType, fType } = newData
            const valid = !!date && !!contractCustomer && !!iType && !!fType
            if (!valid) {
              $message('Error: All fields required')
              reject()
            }
            if (valid) {
              handleAdd(newData)
              resolve()
            }
          })
      }
    : {}

  const handleAdd = formData => {
    const [url, opts] = genCall('new/inspection', 'JSON')
    const data = { ...formData, lastUpdate: stamp(email) }
    axios
      .post(url, data, opts)
      .then(({ data }) => $message(data))
      .catch(console.log)
  }

  return (
    <MaterialTable
      icons={icons}
      options={options}
      title='Inspections'
      columns={cols}
      data={inspections}
      actions={actions}
      editable={editable}
    />
  )
}

export { Table }
