// VALIDATION HELPERS
import { isNumber, limitDecimal } from './number.fn'

const validate = {
  length: (value, digit) => value.length <= digit && isNumber(value),
  hour: value => isNumber(value) && limitDecimal(value, 1) && value * 1 <= 24,
  zip: value => {
    const [dest, route] = value.split('-')
    const destGood = isNumber([dest, route].join('')) && dest.length <= 5
    const routeGood = !!route ? route.length < 5 : true
    return destGood && routeGood
  },
  email: email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
}

const emptyStrings = ['', ' ']

const isEmpty = (v, type) => {
  switch (type) {
    case 'object':
      Object.entries(v).every(([key, val]) => emptyStrings.includes(val))
      break
    default:
      emptyStrings.includes(v)
  }
}

const isFilled = obj => Object.values(obj).every(v => !emptyStrings.includes(v))

export { validate, emptyStrings, isEmpty, isFilled }
