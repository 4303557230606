import React from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
//LOCAL
import { FormButtons } from 'ln_ui'
import { useAllStyles } from 'ln_style'
import { form_actions } from 'ln_core/rootReducer'
import { genCall, stamp, isFilled } from 'ln_util/functions'

const Controls = ({ $message }) => {
  const c = useAllStyles()
  const dp = useDispatch()
  const formData = useSelector(({ forms }) => forms.contractCustomer)
  const { email } = useSelector(({ hub }) => hub.user)

  const isOptional = [
    'parentEntity',
    'maintContactName',
    'maintContactPhone',
    'maintContactEmail'
  ]
  const validDetails = Object.entries(formData).every(
    ([key, value]) => isOptional.includes(key) || !!value
  )
  const validAddress = isFilled(formData.address)
  const valid = validDetails && validAddress

  const submit = () => {
    const [url, opts] = genCall('new/contract-customer', 'JSON')
    const { address, ...rest } = formData
    axios
      .post(url, { ...rest, ...address, ...stamp(email) }, opts)
      .then(({ data }) => {
        $message(data)
        dp(form_actions.contract_customer_clear())
      })
      .catch(console.log)
  }

  return (
    <FormButtons
      className={c.formField}
      submit={submit}
      slice='contractCustomer'
      valid={valid}
    />
  )
}

export default Controls
