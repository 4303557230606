//API HELPERS
const apiCallOpts = {
  default: { headers: { hoot: 'authorized' } },
  JSON: {
    headers: { hoot: 'authorized', 'Content-Type': 'application/json' },
    timeout: 15000
  },
  form: {
    headers: { hoot: 'authorized', 'content-type': 'multipart/form-data' },
    timeout: 15000
  }
}

const genCall = (endpoint, opts = 'default') => [
  `https://app.texasfirelogix.com/${endpoint}`,
  apiCallOpts[opts]
]

export { genCall }