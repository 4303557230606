import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dialog } from '@material-ui/core'
import MaterialTable from 'material-table'
//LOCAL
import { useAllStyles } from 'ln_style'
import { columnMap } from 'ln_util/functions'
import { icons } from 'ln_ui/table'
import { useLookup, useDashboard } from 'ln_util/hooks'
import { columns } from './columns'
import { DocForm } from './'

const Table = ({ $message, location: { search }, history }) => {
  const c = useAllStyles()
  const [form, $form] = useState([])
  const { admin } = useSelector(({ hub }) => hub.user)
  const [cols, $cols] = useState(columns({ history, admin }))
  const clearForm = () => {
    $form([])
    history.push('/jobs')
  }
  const jobs = useDashboard({
    key: 'jobs',
    query: '?active=true'
  })
  const data = jobs.filter(({ jobNumber }) => +jobNumber > 5)
  const customers = useLookup({ collection: 'customers' })

  const params = new URLSearchParams(search)
  const jobDoc = jobs.find(({ hid }) => hid === params.get('jobDoc'))

  const options = {
    pageSize: 20,
    pageSizeOptions: [10, 20, 30],
    grouping: true,
    actionsColumnIndex: -1
  }

  useEffect(() => {
    if (!form.length && !!jobDoc) $form([jobDoc])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobDoc])

  useEffect(() => {
    if (!!customers) {
      const updatedColumns = cols.map(columnMap('details.customer', customers))
      $cols(updatedColumns)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers])

  return (
    <>
      <Dialog
        open={!!form.length}
        onClose={clearForm}
        PaperProps={{ className: c.overflow }}
      >
        <DocForm {...{ form, $message, clearForm }} />
      </Dialog>
      <MaterialTable
        icons={icons}
        options={options}
        title='Jobs'
        columns={cols}
        data={data}
      />
    </>
  )
}

export default Table
