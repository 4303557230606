import React from 'react'
import { Typography, Divider } from '@material-ui/core'
//LOCAL
import { useAllStyles } from 'ln_style'

const SectionHeader = key => {
  const c = useAllStyles()
  return (
    <>
      <Typography variant='h5' className={c.subtitle}>
        {key}
      </Typography>
      <Divider className={c.formField} />{' '}
    </>
  )
}

export default SectionHeader
