import React from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
//LOCAL
import { useAllStyles } from 'ln_style'
import { genCall, stamp } from 'ln_util/functions'
import { FormButtons } from 'ln_ui'

const Controls = ({ $message }) => {
  const c = useAllStyles()
  const formData = useSelector(({ forms }) => forms.po)
  const { email } = useSelector(({ hub }) => hub.user)

  const isOptional = key => ['amount', 'notes'].includes(key)
  const valid = Object.entries(formData).every(
    ([key, value]) => isOptional(key) || !!value
  )

  const submit = () => {
    const [url, opts] = genCall('new/purchase-order', 'JSON')
    axios
      .post(url, { ...formData, ...stamp(email) }, opts)
      .then(({ data }) => $message(data))
      .catch(console.log)
  }

  return (
    <FormButtons
      className={c.formField}
      submit={submit}
      slice='po'
      valid={valid}
    />
  )
}

export { Controls }
