import React from 'react'
import axios from 'axios'
import { GoogleLogin } from 'react-google-login'
import { useDispatch } from 'react-redux'
//LOCAL
import { useAllStyles } from 'ln_style'
import { genCall } from 'ln_util/functions'
import logo from './logo.png'
import { hub_actions } from 'ln_core/rootReducer'
const { login } = hub_actions

const localStyle = ({ spacing }) => ({
  loginPage: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  logoStyle: { height: 'auto', maxWidth: 300 },
  loginStyle: {
    width: 'fit-content',
    height: 'fit-content',
    display: 'block',
    margin: spacing(1)
  }
})

const Login = () => {
  const dp = useDispatch()
  const c = useAllStyles(localStyle)

  const onSignIn = ({ profileObj }) => {
    const compare = (x, y) => x.toLowerCase() === y.toLowerCase()
    const someFn = email => compare(email, profileObj.email)
    axios
      .get(...genCall('api/emails'))
      .then(emails => {
        const authed = emails.data.some(someFn)
        authed &&
          axios
            .get(...genCall(`auths/portal/${profileObj.email}`))
            .then(permissions => {
              dp(login({ ...permissions.data, ...profileObj }))
            })
            .catch(({ message }) => console.dir(message))
      })
      .catch(({ message }) => console.dir(message))
  }

  const authButton = (
    <GoogleLogin
      clientId='549415032326-6v357v43fm0i8v1mq9m2h0vs3i03r77m.apps.googleusercontent.com'
      buttonText='Login'
      onSuccess={onSignIn}
      onFailure={console.log}
      isSignedIn={true}
      cookiePolicy={'single_host_origin'}
    />
  )

  return (
    <div className={c.loginPage}>
      <img alt='texas firelogix logo' src={logo} className={c.logoStyle} />
      <h2>Employee Portal</h2>
      <div className={c.loginStyle}>{authButton}</div>
    </div>
  )
}

export default Login
