import React from 'react'
import { TextField } from '@material-ui/core'
// LOCAL
import { validate } from 'ln_util/functions'
import { CurrencyField, DateField } from '../inputs'

const editText = ({ value, onChange }) => (
  <TextField
    fullWidth
    value={value || ''}
    onChange={({ target: { value } }) => onChange(value)}
  />
)

const editNumber = digit => ({ value, onChange }) => {
  return (
    <TextField
      fullWidth
      value={value || ''}
      onChange={({ target: { value } }) =>
        validate.length(value, digit) && onChange(value)
      }
    />
  )
}

const editHour = ({ value, onChange }) => (
  <TextField
    fullWidth
    value={value || ''}
    onChange={({ target: { value } }) =>
      validate.hour(value) && onChange(value)
    }
  />
)

const editZip = ({ value, onChange }) => (
  <TextField
    fullWidth
    value={value || ''}
    onChange={({ target: { value } }) => validate.zip(value) && onChange(value)}
  />
)

const editCurrency = props => <CurrencyField {...props} />

const editDate = props => <DateField {...props} />

const genEditComponent = (type, data = false) => {
  switch (type) {
    case 'date':
      return editDate
    case 'currency':
      return editCurrency
    case 'zip':
      return editZip
    case 'hour':
      return editHour
    case 'number':
      return editNumber(data)
    default:
      return editText
  }
}

export { genEditComponent }
