import React from 'react'
import { Grid } from '@material-ui/core'

const CustomGrid = ({children}) => {
  const md = Math.floor(12 / +children.length)
  return (
    <Grid container>
      {children.map(child => (
        <Grid item xs={12} md={md}>
          {child}
        </Grid>
      ))}
    </Grid>
  )
}

export default CustomGrid