import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//LOCAL
import { useAllStyles } from 'ln_style'
import { form_actions } from 'ln_core/rootReducer'
import { Controls } from './controls'
import {
  APISelect,
  CustomSnackbar,
  DateField,
  CurrencyField,
  ListSelect,
  NotesField,
  PageWrapper
} from 'ln_ui'

const PO = () => {
  const c = useAllStyles()
  const dp = useDispatch()
  const [message, $message] = useState('')
  const formData = useSelector(({ forms }) => forms.po)

  const update = (type, value) => {
    const output = { ...formData, [type]: value }
    dp(form_actions.po(output))
  }

  const props = key => ({
    className: c.formField,
    value: formData[key],
    onChange: value => update(key, value)
  })

  return (
    <PageWrapper paper title='New Purchase Order'>
      <CustomSnackbar {...{ message, $message, clear: () => dp(form_actions.po_clear()) }} />
      <DateField required label='Date of Purchase' {...props('date')} />
      <APISelect required collection='jobs' withLabel {...props('job')} />
      <ListSelect
        required
        list='supplier'
        withLabel
        creatable
        {...props('supplier')}
      />
      <ListSelect
        required
        list='material_category'
        withLabel
        creatable
        {...props('matCat')}
      />
      <CurrencyField label='Amount' {...props('amount')} />
      <NotesField {...props('notes')} />
      <Controls {...{ $message }} />
    </PageWrapper>
  )
}

export default PO