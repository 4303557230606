import React from 'react'
//LOCAL
import { genEditComponent } from 'ln_ui/table'
import { APISelect } from 'ln_ui'

const columns = ({ manage }) => [
  {
    field: 'jobId',
    title: 'Job',
    editComponent: props => (
      <APISelect collection='jobs' {...props} notListedOpt='Job Not Listed' />
    ),
    headerStyle: { width: 300 },
    removable: false
  },
  {
    field: 'employeeId',
    title: 'Employee',
    editComponent: props => (
      <APISelect
        collection='employees'
        query={`?active=true&payrollType=Hourly&category=${manage.join('%20')}`}
        {...props}
      />
    ),
    headerStyle: { width: 170 },
    removable: false
  },
  {
    field: 'workOrderNumber',
    title: 'WO #',
    editComponent: genEditComponent('number', 5),
    headerStyle: { width: 88 }
  },
  {
    field: 'notes',
    title: 'Notes',
    editComponent: genEditComponent(),
    headerStyle: { width: 240 },
    initialEditValue: ''
  }
]

const genTitle = day => {
  const [dow, month, date] = new Date(day)
    .toDateString()
    .slice(0, 10)
    .split(' ')
  return (
    <>
      {dow}
      <br />
      {month} {date}
    </>
  )
}

const hourProps = {
  editComponent: genEditComponent('hour'),
  headerStyle: { fontSize: 12 },
  initialEditValue: '',
  removable: false,
  grouping: false
}

const genWeekCols = ({
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday
}) => [
  {
    field: 'monday',
    title: genTitle(monday),
    ...hourProps
  },
  {
    field: 'tuesday',
    title: genTitle(tuesday),
    ...hourProps
  },
  {
    field: 'wednesday',
    title: genTitle(wednesday),
    ...hourProps
  },
  {
    field: 'thursday',
    title: genTitle(thursday),
    ...hourProps
  },
  {
    field: 'friday',
    title: genTitle(friday),
    ...hourProps
  },
  {
    field: 'saturday',
    title: genTitle(saturday),
    ...hourProps
  },
  {
    field: 'sunday',
    title: genTitle(sunday),
    ...hourProps
  }
]

export { columns, genWeekCols }
