import { createMuiTheme } from '@material-ui/core/styles'

const palette = {
  primary: { main: '#424242', contrastText: '#ffffff' },
  secondary: { main: '#A51D00', contrastText: '#ffffff' }
}
const themeName = 'AdminTheme'
const overrides = {
  MuiTableCell: { root: { padding: 8 } },
  MuiInput: { root: { fontSize: 'inherit' } },
  MuiInputLabel: { outlined: { zIndex: 0 } }
}
const themeObj = {
  palette,
  themeName,
  overrides
}

export default createMuiTheme(themeObj)
