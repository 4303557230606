import { toPath, capitalize } from 'ln_util/functions'
import Home from 'ln_pages/home'
import Time from 'ln_pages/time'
import PO from 'ln_pages/po'
import Job from 'ln_pages/job'
import Customer from 'ln_pages/customer'
import Inspection from 'ln_pages/inspection'
import Jobs from 'ln_pages/jobs'
import Times from 'ln_pages/times'
import Invoice from 'ln_pages/invoice'
import ContractCustomer from 'ln_pages/contractCustomer'
import PurchaseOrders from 'ln_pages/purchaseOrders'
import Employee from 'ln_pages/employee'
import QuickJob from 'ln_pages/quickJob'

const genRoute = (
  name,
  component,
  section,
  nodeId,
  icon = 'square',
  noMenu = false
) => ({
  path: toPath(name),
  text: capitalize(name),
  component,
  noMenu,
  icon,
  section,
  nodeId
})

const routes = [
  // { path: '/', text: 'Home', Home, noMenu: true },
  genRoute('contract customer', ContractCustomer, 'Forms', '3', 'address-card'),
  genRoute('customer', Customer, 'Forms', '4', 'address-book'),
  genRoute('employee', Employee, 'Forms', '5', 'id-badge'),
  genRoute('invoice mailer', Invoice, 'Forms', '6', 'envelope'),
  genRoute('job', Job, 'Forms', '7', 'folder'),
  genRoute('quick job', QuickJob, 'Forms', '8', 'folder'),
  genRoute('purchase order', PO, 'Forms', '9', 'receipt'),
  genRoute('time', Time, 'Forms', '10', 'user-clock'),
  genRoute('inspections', Inspection, 'Dashboards', '11', 'calendar'),
  genRoute('jobs', Jobs, 'Dashboards', '12', 'clipboard-list'),
  genRoute('purchase orders', PurchaseOrders, 'Dashboards', '13', 'list-ol'),
  genRoute('time entries', Times, 'Dashboards', '14', 'business-time')
]

export default routes
