import React, { useState } from 'react'
import axios from 'axios'
import objectToFormData from 'json-form-data'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, Checkbox } from '@material-ui/core'
//LOCAL
import {
  PageWrapper,
  APISelect,
  FileUpload,
  FormButtons,
  CustomSnackbar
} from 'ln_ui'
import { form_actions } from 'ln_core/rootReducer'
import { useAllStyles } from 'ln_style'
import { genCall, stamp } from 'ln_util/functions'

const localStyle = ({spacing}) => ({
  formControlLabel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  checkbox: {
    padding: spacing(.5, .5)
  }
})

const Invoice = props => {
  const c = useAllStyles(localStyle)
  const dp = useDispatch()
  const [message, $message] = useState('')
  const formData = useSelector(({ forms }) => forms.invoice)
  const { email } = useSelector(({ hub }) => hub.user)
  const clear = () => dp(form_actions.invoice_clear())
  const update = (type, value) => {
    const output = { ...formData, [type]: value }
    dp(form_actions.invoice(output))
  }

  const submit = () => {
    const [url, opts] = genCall('mail/invoice', 'form')
    const data = objectToFormData({...formData, ...stamp(email)})
    axios
      .post(url, data, opts)
      .then(({ data }) => $message(data))
      .catch(console.log)
  }

  return (
    <PageWrapper paper title='Invoice Mailer'>
      <CustomSnackbar autoHide {...{ message, $message, clear }} />
      <div className={c.formControlLabel}>
        <FormControlLabel
          checked={formData.pastDue}
          onChange={() => update('pastDue', !formData.pastDue)}
          control={<Checkbox className={c.checkbox} />}
          label={'Send multiple past due invoices'}
        />
      </div>
      <APISelect
        withLabel
        className={c.formField}
        collection='customers'
        value={formData.customer}
        onChange={value => update('customer', value)}
      />
      <FileUpload
        single={!formData.pastDue}
        className={c.formField}
        value={formData.files}
        onChange={value => update('files', value)}
        label={`Attach Invoic${formData.pastDue ? 'e(s)' : 'e'}`}
      />
      <FormButtons
        className={c.formField}
        submit={submit}
        slice='invoice'
        valid={!!formData.customer && formData.files.length}
      />
    </PageWrapper>
  )
}

export default Invoice
