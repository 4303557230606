import React from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
//LOCAL
import { TextField, DateField, FormButtons } from 'ln_ui'
import { form_actions, dashboard_actions } from 'ln_core/rootReducer'
import { useAllStyles } from 'ln_style'
import { genCall, stamp } from 'ln_util/functions'

const Form = ({ form, $form, $message }) => {
  const c = useAllStyles()
  const dp = useDispatch()
  const formData = useSelector(({ forms }) => forms.inspection)
  const { email } = useSelector(({ hub }) => hub.user)

  const update = (type, value) => {
    const output = { ...formData, [type]: value }
    dp(form_actions.inspection(output))
  }

  const submit = () => {
    const [url, opts] = genCall('complete/inspection', 'JSON')
    const data = { inspections: form, formData, lastUpdate: stamp(email) }
    axios
      .post(url, data, opts)
      .then(({ data: {inspections, message} }) => {
        dp(dashboard_actions.inspections(inspections))
        $message(message)
        $form([])
      })
      .catch(console.log)
  }

  return (
    <>
      <DialogTitle>Completion Details</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          className={c.formField}
          label='Bill Reference'
          value={formData.billRef}
          onChange={value => update('billRef', value)}
        />
        <DateField
          fullWidth
          required
          className={c.formField}
          label='Completion Date'
          value={formData.completionDate}
          onChange={value => update('completionDate', value)}
        />
      </DialogContent>
      <DialogActions>
        <FormButtons
          submit={submit}
          slice='inspection'
          valid={!!formData.completionDate}
        />
      </DialogActions>
    </>
  )
}

export { Form }
