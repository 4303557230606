import React from 'react'
import { useSelector } from 'react-redux'
import { IconButton, Icon, Tooltip } from '@material-ui/core'
//LOCAL
import { useAllStyles } from 'ln_style'
import { TextField, AddressField } from 'ln_ui'
import { isFilled } from 'ln_util/functions'

const localStyle = ({ spacing }) => ({
  iconButton: {
    width: spacing(6),
    height: spacing(6),
    marginLeft: spacing(2)
  }
})

const Owner = ({ value, onChange }) => {
  const c = useAllStyles(localStyle)
  const {
    details: { customer },
    address
  } = useSelector(({ forms }) => forms.job)
  const { customers } = useSelector(({ lookups }) => lookups)

  const update = (k, v) => {
    const output = { ...value, [k]: v }
    onChange(output)
  }

  const props = key => ({
    className: c.formField,
    value: value[key],
    onChange: value => update(key, value)
  })

  return (
    <>
      <div className={c.flex}>
        {!!customer && (
          <Tooltip title='Paste Customer' placement='top'>
            <IconButton
              className={c.iconButton}
              onClick={() => update('name', customers[customer])}>
              <Icon className='fas fa-paste' />
            </IconButton>
          </Tooltip>
        )}
        <TextField required label="Owner's Full Name" {...props('name')} />
      </div>
      <div className={c.flex}>
        {isFilled(address) && (
          <Tooltip title='Paste Project Address' placement='top'>
            <IconButton
              className={c.iconButton}
              onClick={() => update('address', address)}>
              <Icon className='fas fa-paste' />
            </IconButton>
          </Tooltip>
        )}
        <AddressField required {...props('address')} />
      </div>
    </>
  )
}

export { Owner }
