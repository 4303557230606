import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//LOCAL
import {
  PageWrapper,
  CustomSnackbar,
  TextField,
  SectionHeader,
  APISelect,
  AddressField,
  NotesField
} from 'ln_ui'
import { form_actions } from 'ln_core/rootReducer'
import { useAllStyles } from 'ln_style'
import Controls from './controls'

const ContractCustomer = props => {
  const c = useAllStyles()
  const dp = useDispatch()
  const [message, $message] = useState('')
  const formData = useSelector(({ forms }) => forms.contractCustomer)
  const update = (type, value) => {
    const output = { ...formData, [type]: value }
    dp(form_actions.contractCustomer(output))
  }

  const genProps = type => ({
    className: c.formField,
    value: formData[type],
    onChange: value => update(type, value)
  })

  return (
    <PageWrapper paper title='New Contract Customer'>
      <CustomSnackbar autoHide {...{ message, $message }} />
      <TextField
        autoFocus
        label='Customer Name'
        required
        {...genProps('contractCustomer')}
      />
      <TextField label='Parent Company' {...genProps('parentEntity')} />
      <APISelect
        withLabel
        required
        collection='customers'
        {...genProps('customer')}
      />
      {SectionHeader('Address')}
      <AddressField
        required
        value={formData.address}
        onChange={value => update('address', value)}
      />
      {SectionHeader('Contract Contact')}
      <TextField label='Name' required {...genProps('contractContactName')} />
      <TextField label='Email' required {...genProps('contractContactEmail')} />
      {SectionHeader('Maintenance Contact')}
      <TextField label='Name' {...genProps('maintContactName')} />
      <TextField label='Email' {...genProps('maintContactEmail')} />
      <TextField label='Phone' {...genProps('maintContactPhone')} />
      <NotesField section {...genProps('notes')} />
      <Controls {...{ $message }} />
    </PageWrapper>
  )
}

export default ContractCustomer
