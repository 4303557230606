import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//LOCAL
import { lookup_actions } from 'ln_core/rootReducer'
import { genCall } from 'ln_util/functions'
import { useAPI } from 'ln_util/hooks'

const useLookup = ({ collection, query = '?active=true' }) => {
  const dp = useDispatch()
  const list = useSelector(({ lookups }) => lookups[collection])

  const { isLoading, data } = useAPI(
    ...genCall(`lookup/${collection}${query}`)
  )

  useEffect(() => {
    if (!isLoading && !!data) {
      dp(lookup_actions[collection](data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return list
}

export { useLookup }
