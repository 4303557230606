import React from 'react'
import axios from 'axios'
import objectToFormData from 'json-form-data'
import { useDispatch, useSelector } from 'react-redux'
//LOCAL
import { ListSelect, FileUpload, FormButtons } from 'ln_ui'
import { form_actions } from 'ln_core/rootReducer'
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { useAllStyles } from 'ln_style'
import { genCall, stamp } from 'ln_util/functions'

const DocForm = ({ form, $message, clearForm }) => {
  const c = useAllStyles()
  const dp = useDispatch()
  const formData = useSelector(({ forms }) => forms.jobDoc)
  const { email } = useSelector(({ hub }) => hub.user)
  const update = (type, value) => {
    const output = { ...formData, [type]: value }
    dp(form_actions.jobDoc(output))
  }

  const submit = () => {
    const [url, opts] = genCall('new/job-document', 'form')
    const data = objectToFormData({
      job: form[0],
      ...formData,
      ...stamp(email)
    })
    axios
      .post(url, data, opts)
      .then(({ data }) => {
        $message(data)
        dp(form_actions.jobDoc_clear())
        clearForm()
      })
      .catch(console.log)
  }

  return (
    <>
      <DialogTitle style={{ width: '40vw' }}>Upload Job Document</DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <ListSelect
          multiSelect
          className={c.formField}
          list='document_types'
          value={formData.types}
          onChange={value => update('types', value)}
        />
        <FileUpload
          single
          className={c.formField}
          value={formData.files}
          onChange={value => update('files', value)}
        />
      </DialogContent>
      <DialogActions>
        <FormButtons submit={submit} slice='jobDoc' valid={true} />
      </DialogActions>
    </>
  )
}

export default DocForm
