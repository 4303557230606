import React, { forwardRef } from 'react'
import AddBox from '@material-ui/icons/AddBox'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const withRef = Component => forwardRef((props, ref) => (<Component {...{...props, ref} } />))

const icons = {
  Add: withRef(AddBox),
  Check: withRef(Check),
  Clear: withRef(Clear),
  Delete: withRef(DeleteOutline),
  DetailPanel: withRef(ChevronRight),
  Edit: withRef(Edit),
  Export: withRef(SaveAlt),
  Filter: withRef(FilterList),
  FirstPage: withRef(FirstPage),
  LastPage: withRef(LastPage),
  NextPage: withRef(ChevronRight),
  PreviousPage: withRef(ChevronLeft),
  ResetSearch: withRef(Clear),
  Search: withRef(Search),
  SortArrow: withRef(ArrowUpward),
  ThirdStateCheck: withRef(Remove),
  ViewColumn: withRef(ViewColumn)
}

export { icons }