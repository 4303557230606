import React from 'react'
import { useSelector } from 'react-redux'
import { Paper, Typography } from '@material-ui/core'
// LOCAL
import { useAllStyles } from 'ln_style'
import { cladd } from 'ln_util/functions'

const PageWrapper = ({ children, paper = false, title = false }) => {
  const menu = useSelector(({ hub: { menu } }) => menu)
  const localStyles = ({ spacing, breakpoints: { down } }) => ({
    wrapper: {
      margin: spacing(2),
      marginLeft: menu ? 0 : spacing(2),
      width: '100%',
      [down('sm')]: {
        margin: spacing(1),
        marginLeft: menu ? 0 : spacing(1)
      }
    }
  })
  const c = useAllStyles(localStyles)
  const className = menu ? c.content : c.contentShift
  
  return paper ? (
    <div className={c.wrapper}>
      <Paper className={cladd(c.paper, className)}>
        {!!title && (
          <Typography
            variant='h4'
            className={c.title}
            align='center'
            color='secondary'
          >
            {title}
          </Typography>
        )}
        {children}
      </Paper>
    </div>
  ) : (
    <div className={cladd(c.wrapper, className)}>{children}</div>
  )
}

export default PageWrapper
