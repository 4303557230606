import React from 'react'
// LOCAL
import { genInputProps } from 'ln_util/functions'
import { useLookup } from 'ln_util/hooks'
import { ComboBox } from './comboBox'

const APISelect = ({ collection, required = false, withLabel = false, query, ...rest }) => {
  const list = useLookup({ collection, query })
const { label } = genInputProps(collection.slice(0, -1))
  const options = Object.entries(list).map(([k, v]) => ({ value: k, label: v }))

  const props = {
    ...rest,
    options,
    textFieldProps: withLabel ? { label, required } : { required }
  }

  return <ComboBox {...props} />
}

export { APISelect }
