import React from 'react'
//LOCAL
import { TextField } from './'
import SectionHeader from '.././sectionHeader'

const NotesField = ({section = false, ...props}) => {
  return section ? (
    <>
      {SectionHeader('Notes')}
      <TextField
        {...props}
        multiline
        rowsMax='4'
        // label='Notes'
        variant='outlined'
      />
    </>
  ) : (
    <TextField
      {...props}
      multiline
      rowsMax='4'
      label='Notes'
      variant='outlined'
    />
  )
}

export { NotesField }
