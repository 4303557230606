import React, { useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
//LOCAL
import {
  PageWrapper,
  Grid,
  TextField,
  FormButtons,
  CustomSnackbar,
  DateField,
  CurrencyField,
  AddressField,
  NotesField,
  SectionHeader,
  ListSelect
} from 'ln_ui'
import { form_actions } from 'ln_core/rootReducer'
import { useAllStyles } from 'ln_style'
import { genCall, stamp, isFilled } from 'ln_util/functions'

const Employee = props => {
  const c = useAllStyles()
  const dp = useDispatch()
  const [message, $message] = useState('')
  const formData = useSelector(({ forms }) => forms.employee)
  const { email } = useSelector(({ hub }) => hub.user)
  const clear = () => dp(form_actions.employee_clear())
  const update = (type, value) => {
    const output = { ...formData, [type]: value }
    dp(form_actions.employee(output))
  }

  const genProps = key => ({
    className: c.formField,
    value: formData[key],
    onChange: value => update(key, value)
  })

  const isOptional = [
    'nickname',
    'asanaGID',
    'cell',
    'personalEmail',
    'workEmail',
    'authEmail',
    'emergencyContactName',
    'emergencyContactPhone',
    'qbServiceItem',
    'notes'
  ]
  const validDetails = Object.entries(formData).every(
    ([key, value]) => isOptional.includes(key) || !!value
  )
  const validAddress = isFilled(formData.address)
  const valid = validDetails && validAddress

  const submit = () => {
    const [url, opts] = genCall('new/employee', 'JSON')
    const { address, ...rest } = formData
    const data = { ...address, ...rest, created: stamp(email) }
    axios
      .post(url, data, opts)
      .then(({ data }) => $message(data))
      .catch(console.log)
  }

  return (
    <PageWrapper paper title='New Employee'>
      <CustomSnackbar autoHide {...{ message, $message, clear }} />
      {SectionHeader('Name')}
      <Grid>
        <TextField label='First Name' {...genProps('firstName')} required />
        <TextField label='Last Name' {...genProps('lastName')} required />
        <TextField label='Nickname' {...genProps('nickname')} />
      </Grid>
      {SectionHeader('Address')}
      <AddressField {...genProps('address')} required />
      {SectionHeader('Identification')}
      <Grid>
        <DateField label='Date of Birth' {...genProps('dob')} required />
        <TextField label='SSN' {...genProps('ssn')} required digits={4} />
        <TextField label='DL' {...genProps('dl')} required digits={8} />
      </Grid>
      {SectionHeader('Contact')}
      <Grid>
        <TextField label='Cell Phone' {...genProps('cell')} />
        <TextField label='Personal Email' {...genProps('personalEmail')} />
      </Grid>
      <Grid>
        <TextField
          label='Authorized Email'
          {...genProps('authEmail')}
          helperText='Email authorized to be sent confidential records.'
        />
        <TextField label='Work Email' {...genProps('workEmail')} />
      </Grid>
      {SectionHeader('Emergency Contact')}
      <Grid>
        <TextField label='Name' {...genProps('emergencyContactName')} />
        <TextField label='Phone' {...genProps('emergencyContactPhone')} />
      </Grid>
      {SectionHeader('Hiring Information')}
      <Grid>
        <CurrencyField label='Wage' {...genProps('wage')} required />
        <ListSelect
          withLabel
          list='payroll_type'
          {...genProps('payrollType')}
          required
        />
        <ListSelect
          withLabel
          list='employee_category'
          {...genProps('category')}
          required
        />
        <ListSelect
          withLabel
          list='compesation_class'
          {...genProps('wcClass')}
          required
        />
      </Grid>
      <NotesField section {...genProps('notes')} />
      <FormButtons
        className={c.formField}
        submit={submit}
        slice='employee'
        valid={valid}
      />
    </PageWrapper>
  )
}

export default Employee
