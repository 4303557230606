//STRING HELPERS
const toPath = str =>
  '/' +
  str
    .toLowerCase()
    .split(' ')
    .join('-')

const capitalize = str =>
  str
    .split(' ')
    .map(([f, ...rest]) => f.toUpperCase() + rest.join(''))
    .join(' ')

const genInputProps = str => {
  const label = str
    .split('_')
    .map(capitalize)
    .join(' ')
  const lowercase = str
    .toLowerCase()
    .split('_')
    .join(' ')
  const firstLetter = lowercase[0]
  const n = ['a', 'e', 'i', 'o', 'u'].some(vowel => vowel === firstLetter)
  const placeholder = `Select a${n ? 'n' : ''} ${lowercase}`
  return { label, placeholder }
}

export { toPath, capitalize, genInputProps }
