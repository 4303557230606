import React from 'react'
import { useSelector } from 'react-redux'
//LOCAL
import 'ln_style/App.css'
import Login from 'ln_pages/login'
import routes from 'ln_util/routes'
import { useAllStyles } from 'ln_style'
import { RootAppBar, RootDrawer, PrivateRoute } from 'ln_ui'

function App() {
  const { flex } = useAllStyles()
  const { loggedIn } = useSelector(({ hub }) => hub)

  return !!loggedIn ? (
    <>
      <RootAppBar />
      <div className={flex}>
        <RootDrawer />
        {routes.map(({ path, component, text }) => (
          <PrivateRoute key={text} exact={true} {...{ path, component }} />
        ))}
      </div>
    </>
  ) : (
    <Login />
  )
}

export default App
