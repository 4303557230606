import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import { GolfCourse } from '@material-ui/icons'
//LOCAL
import { columnMap } from 'ln_util/functions'
import { icons } from 'ln_ui/table'
import { useLookup, useDashboard, useDrawer } from 'ln_util/hooks'
import { columns } from './columns'
import { Vacation } from './vacation'

const Table = ({ $message }) => {
  const [cols, $cols] = useState(columns)
  const { admin, email } = useSelector(({hub}) => hub.user)
  const hasPerms = admin.includes('time-entries')
  const [openDrawer, Drawer] = useDrawer()
  const time = useDashboard({ key: 'time' })
  const data = hasPerms ? time : time.filter(({_email}) => _email === email)
  const employees = useLookup({
    collection: 'employees'
  })
  const jobs = useLookup({ collection: 'jobs' })

  const options = {
    pageSize: 20,
    pageSizeOptions: [10, 20, 30],
    grouping: true
  }

  const actions = [
    {
      isFreeAction: true, //display on toolbar
      icon: GolfCourse,
      tooltip: 'PTO',
      onClick: openDrawer
    }
  ]

  useEffect(() => {
    if (!!employees && !!jobs) {
      const updatedColumns = cols
        .map(columnMap('employeeId', employees))
        .map(columnMap('jobId', jobs))

      $cols(updatedColumns)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees, jobs])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs])

  return (
    <>
      <Drawer>
        <Vacation />
      </Drawer>
      <MaterialTable
        icons={icons}
        options={options}
        actions={actions}
        title='Time Entries'
        columns={cols}
        data={data}
      />
    </>
  )
}

export default Table
