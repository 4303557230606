import React from 'react'
import { Grid } from '@material-ui/core'
//LOCAL
import { useAllStyles } from 'ln_style'
import { TextField, ZipField, ListSelect } from 'ln_ui'
import { genInputProps } from 'ln_util/functions'

const AddressField = ({ value, onChange, required = false }) => {
  const c = useAllStyles()

  const update = (k, v) => {
    const output = { ...value, [k]: v }
    onChange(output)
  }

  const props = key => ({
    required,
    className: c.formField,
    value: value[key],
    onChange: value => update(key, value),
    label: genInputProps(key).label
  })
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField {...props('street')} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField {...props('city')} />
      </Grid>
      <Grid item xs={12} md={3}>
        <ListSelect
          list='state'
          withLabel
          {...props('state')}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ZipField {...props('zip')} />
      </Grid>
    </Grid>
  )
}

export { AddressField }
