import { inits } from './init'

//STORE HELPERS
export const genReducer = (list, init = []) => (
  state = init,
  { type, action }
) => (list.includes(type) ? action(state) : state)

export const genGenType = base => str =>
  `${base.toUpperCase()}_${str
    .toUpperCase()
    .split(' ')
    .join('_')}`

export const genCollectionActions = type => (acc, cur) => {
  const genType = genGenType(type)
  return {
    ...acc,
    [cur]: data => ({
      type: genType(`set ${cur}`),
      action: state => ({ ...state, [cur]: data })
    }),
    [`${cur}_clear`]: data => ({
      type: genType(`clear ${cur}`),
      action: state => ({ ...state, [cur]: inits[type][cur] })
    })
  }
}

const genActions = (init, actionRoot) => {
  const collections = Object.keys(init)
  const actionList = collections.reduce(genCollectionActions(actionRoot), {})
  const types = Object.entries(actionList).map(([k, v]) => v().type)
  return [actionList, types]
}
export default genActions
