const address = {
  street: '',
  city: '',
  state: 'Texas',
  zip: ''
}

const forms = {
  jobDoc: {
    types: '',
    files: []
  },
  time: [
    // {
    //   jobId: '',
    //   employeeId: '',
    //   monday: 0,
    //   tuesday: 0,
    //   wednesday: 0,
    //   thursday: 0,
    //   friday: 0,
    //   saturday: 0,
    //   sunday: 0
    // }
  ],
  po: {
    date: new Date(),
    job: '',
    amount: '',
    matCat: '',
    supplier: '',
    notes: ''
  },
  job: {
    active: true,
    details: {
      name: '',
      customer: '',
      salesman: '',
      amount: ''
    },
    address,
    owner: {
      name: '',
      address
    },
    timeline: {
      startDate: null,
      endDate: null,
      date: null
    },
    meta: {
      notes: ''
    },
    setup: {
      contract: false,
      sov: false,
      proposal: false,
      taxExempt: false,
      publicJob: false,
      wageScale: false,
      fitterWage: '',
      helperWage: '',
      coi: false,
      jobSpecificCoi: false,
      coiMsg: '',
      coiAttachments: []
    }
  },
  customer: {
    active: true,
    customer: '',
    address,
    primary: {
      name: '',
      phone: '',
      email: ''
    },
    secondary: {
      name: '',
      phone: '',
      email: '',
      cc: false
    },
    notes: ''
  },
  inspection: {
    billRef: '',
    completionDate: new Date()
  },
  invoice: {
    pastDue: false,
    customer: '',
    files: []
  },
  contractCustomer: {
    active: true,
    contractCustomer: '',
    parentEntity: '',
    customer: '',
    address,
    contractContactName: '',
    contractContactEmail: '',
    maintContactName: '',
    maintContactPhone: '',
    maintContactEmail: '',
    notes: ''
  },
  employee: {
    dob: null,
    active: true,
    lastName: '',
    firstName: '',
    nickname: '',
    ssn: '',
    dl: '',
    asanaGID: '',
    address,
    cell: '',
    personalEmail: '',
    workEmail: '',
    authEmail: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    payrollType: '',
    category: '',
    wcClass: '',
    wage: '',
    qbServiceItem: '',
    notes: ''
  },
  quickJob: {
    active: false,
    details: {
      name: '',
      customer: '',
      salesman: '',
      amount: ''
    },
    timeline: {
      startDate: null,
      endDate: null,
      date: null
    },
    meta: {
      notes: '',
      quick: true,
      certPay: false
    }
  }
}

export const inits = {
  lookup: {
    customers: false,
    contract_customers: false,
    jobs: false,
    employees: false
  },
  dashboards: {
    po: [],
    time: [],
    inspections: [],
    jobs: []
  },
  forms
}
