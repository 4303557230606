import { genGenType } from 'ln_core/fns'
const genType = genGenType('hub')

export const init = {
  menu: true,
  loggedIn: false,
  user: null
}

export const toggleMenu = data => ({
  type: genType('toggle menu'),
  action: state => ({ ...state, menu: !state.menu })
})

export const login = ({logout = false, ...user}) => ({
  type: genType('login'),
  action: state => {
    state.loggedIn = !logout
    state.user = user || {}
    return { ...state }
  }
})

const types = [toggleMenu, login].map(li => li({}).type)

export default types
