import React, { useState, useEffect } from 'react'
import { TextField, InputAdornment } from '@material-ui/core'
// LOCALc
import { isNumber, limitDecimal, formatMoney } from 'ln_util/functions'

const CurrencyField = ({ onChange, value, ...props }) => {
  const [local, $local] = useState(value)

  const handleChange = ({ target }) => {
    if (target.value[0] === '$') target.value = target.value.substr(1)
    if (isNumber(target.value) && limitDecimal(target.value, 2))
      $local(target.value)
  }
  const handleFocus = ({ target }) =>
    !!target.value && $local(target.value.split(',').join(''))
  const handleBlur = ({ target }) => {
    if (target.value[0] === '$') target.value = target.value.substr(1)
    if (isNumber(target.value) && limitDecimal(target.value, 2)) {
      const money = formatMoney(target.value)
        .substr(1).split(',').join('')
      onChange(money)
    }
    !!target.value && $local(formatMoney(target.value).substr(1))
  }

  useEffect(() => {
    if (value === '') $local('')
  }, [value])

  return (
    <TextField
      {...props}
      value={local}
      fullWidth
      InputProps={{
        startAdornment: <InputAdornment position='start'>$</InputAdornment>
      }}
      InputLabelProps={{ shrink: true }}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  )
}

export {CurrencyField}