import React from 'react'
import { TextField } from '@material-ui/core'
//LOCAL
import { useLocalState } from 'ln_util/hooks'
import { isNumber } from 'ln_util/functions'

const StatefulField = ({ value, onChange, digits = false, ...rest }) => {
  const [local, $local] = useLocalState(value)
  const isValid = value => {
    if (!!value) return value.length <= digits && isNumber(value)
    return false
  }
  return (
    <TextField
      {...rest}
      fullWidth
      value={local || ''}
      onChange={({ target: { value } }) => {
        if (!!!digits || isValid(value)) $local(value)
      }}
      onBlur={() => !!local && onChange(local.trim())}
      InputLabelProps={{ shrink: true }}
    />
  )
}

export default StatefulField
