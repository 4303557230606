import { makeStyles } from '@material-ui/styles'

const globalStyles = ({
  breakpoints: { down },
  palette: {
    common: { white }
  },
  spacing
}) => ({
  overflow: { overflow: 'visible' },
  grow: { flexGrow: 1 },
  hide: { display: 'none !important' },
  white: { color: white },
  flex: { display: 'flex' },
  ml1: { marginLeft: spacing(1) },
  ml2: { marginLeft: spacing(2) },
  mr1: { marginRight: spacing(1) },
  mr2: { marginRight: spacing(2) },
  m2: { margin: spacing(2) },
  mb2: { marginBottom: spacing(2) },
  mt2: { marginTop: spacing(2) },
  pointer: { cursor: 'pointer' },
  noPad: { padding: 0 },
  center: { textAlign: 'center' },
  paper: {
    overflow: 'auto',
    margin: 'auto',
    width: 750,
    [down('sm')]: {
      // margin: spacing(1, 0),
      width: '100%'
    }
  },
  title: {
    margin: spacing(2)
  },
  subtitle: {
    margin: spacing(2),
    marginBottom: 0
  },
  formField: {
    margin: spacing(0, 2),
    marginBottom: spacing(2),
    width: 'calc(100% - 32px)'
  }
  // form: { maxWidth: 800, margin: 'auto' }
})

const useStyles = (...styleList) =>
  styleList.reduce((ac, cu) => ({ ...ac, ...makeStyles(cu)() }), {})

const useAllStyles = (...extras) =>
  useStyles(...extras, globalStyles)

export { useStyles, useAllStyles }
