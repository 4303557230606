import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//LOCAL
import { dashboard_actions } from 'ln_core/rootReducer'
import { genCall } from 'ln_util/functions'
import { useAPI } from 'ln_util/hooks'

const useDashboard = ({key, query = '', mapFn = data => data}) => {
  const dp = useDispatch()
  const list = useSelector(({ dashboards }) => dashboards[key])

  const { isLoading, data } = useAPI(...genCall(`api/${key}${query}`))

  useEffect(() => {
    if (!isLoading && !!data && !list.length) {
      const output = data.map(mapFn)
      dp(dashboard_actions[key](output))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return list
}

export { useDashboard }
