import React, { useEffect } from 'react'
import { Icon, Button, ListItem, ListItemIcon } from '@material-ui/core'
// LOCAL
import { useAllStyles } from 'ln_style'

const FileUpload = ({
  value,
  onChange,
  label = 'Upload File',
  single = false,
  // accept = null,
  icon = 'file-upload'
}) => {
  const c = useAllStyles()
  const handleChange = ({ target }) => {
    console.log(target)
    if (!!target.files.length) {
      single
        ? onChange([target.files[0]])
        : onChange(Object.entries(target.files).map(([key, val]) => val))
    }
  }

  useEffect(() => {
    console.log(value)
    if (single && value.length > 1) onChange([value[0]])
  }, [single, value, onChange])

  return (
    <div className={c.formField}>
      <input
        value=''
        type='file'
        className={c.hide}
        onChange={handleChange}
        multiple={!single}
        id='contained-button-file'
        // accept={accept}
      />
      <label htmlFor='contained-button-file'>
        <Button
          variant={!!value.length ? 'contained' : 'outlined'}
          fullWidth
          color='primary'
          component='span'
        >
          <ListItemIcon className={!!value.length ? c.white : null}>
            <Icon className={`fas fa-${icon}`} classes={{ root: c.overflow }} />
          </ListItemIcon>
          <div style={{ paddingTop: 2 }}>{label}</div>
        </Button>
      </label>
      {!!value.length &&
        value.map((file, i) => (
          <ListItem key={i}>
            <ListItemIcon>
              <Icon className='fas fa-paperclip' />
            </ListItemIcon>
            {file.name}
          </ListItem>
        ))}
    </div>
  )
}

export { FileUpload }
