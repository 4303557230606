//TIME HELPERS
import { addDays, startOfWeek } from 'date-fns'

const genThisWeek = day => {
  const date = new Date(day)
  const monday = startOfWeek(date, { weekStartsOn: 1 })
  return {
    selected: date,
    monday: monday,
    tuesday: addDays(monday, 1),
    wednesday: addDays(monday, 2),
    thursday: addDays(monday, 3),
    friday: addDays(monday, 4),
    saturday: addDays(monday, 5),
    sunday: addDays(monday, 6)
  }
}

const genTimestamp = () => new Date().toISOString()

const dateSort = (a, b) =>
  new Date(a.date).valueOf() - new Date(b.date).valueOf()

const humanDate = date => new Date(date).toLocaleString().split(',')[0]


export { addDays, genThisWeek, genTimestamp, dateSort, humanDate }
