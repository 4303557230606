import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
//LOCAL
import { columnMap } from 'ln_util/functions'
import { icons } from 'ln_ui/table'
import { useLookup, useDashboard } from 'ln_util/hooks'
import { columns } from './columns.js'

const Table = () => {
  const [cols, $cols] = useState(columns)
  const purchaseOrders = useDashboard({ key: 'po' })
  const jobs = useLookup({ collection: 'jobs' })
  
  const options = {
    pageSize: 20,
    pageSizeOptions: [10, 20, 30],
    grouping: true,
  }

  useEffect(() => {
    if (!!jobs) {
      const updatedColumns = cols.map(columnMap('job', jobs))
      $cols(updatedColumns)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs])

  return (
    <MaterialTable
      icons={icons}
      options={options}
      title='Purchase Orders'
      columns={cols}
      data={purchaseOrders}
    />
  )
}

export default Table
