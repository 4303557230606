import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable, { MTableToolbar } from 'material-table'
import { CalendarToday, FileCopy, Info } from '@material-ui/icons'
//LOCAL
import { columnMap, genThisWeek, addDays } from 'ln_util/functions'
import { form_actions } from 'ln_core/rootReducer'
import { useDatePicker, useDrawer, useLookup } from 'ln_util/hooks'
import { columns, genWeekCols } from './columns'
import { icons } from 'ln_ui/table'
import { dowArr } from 'ln_util/constants'
import { useAllStyles } from 'ln_style'
import { Controls } from './controls'
import { Totals } from './totals'

const Table = ({ $message }) => {
  const c = useAllStyles()
  const dp = useDispatch()
  const list = useSelector(({ forms }) => forms.time)
  const { manage } = useSelector(({ hub }) => hub.user)
  const [week, $week] = useState(genThisWeek(addDays(new Date(), -7)))
  const [cols, $cols] = useState([...columns({ manage }), ...genWeekCols(week)])
  const [openPicker, Picker] = useDatePicker()
  const [openDrawer, Drawer] = useDrawer()

  //TOOLBAR
  const Toolbar = props => (
    <div className={c.flex}>
      <div className={c.grow}>
        <MTableToolbar {...props} />
      </div>
      <Controls {...{ week, $message }} />
    </div>
  )

  //TABLE ACTIONS
  const updateWeek = day => {
    $week(genThisWeek(day))
    const removed = cols.filter(
      ({ field }) => !dowArr.some(dow => dow === field)
    )
    $cols([...removed, ...genWeekCols(genThisWeek(day))])
  }

  //OPTIONS
  const options = {
    paging: false,
    grouping: true,
    sorting: false
  }

  //ACTIONS
  const actions = [
    {
      icon: FileCopy,
      tooltip: 'Copy',
      onClick: (event, rowData) => {
        const output = [
          ...list,
          {
            ...rowData,
            tableData: { id: list.length }
          }
        ]
        dp(form_actions.time(output))
        $message('Copied')
      }
    },
    {
      isFreeAction: true, //display on toolbar
      icon: CalendarToday,
      tooltip: 'Change Week',
      onClick: openPicker
    },
    {
      isFreeAction: true, //display on toolbar
      icon: Info,
      tooltip: list.length ? 'See Totals' : false,
      onClick: openDrawer,
      disabled: !list.length
    }
  ]

  const isValid = newData => {
    const { jobId, employeeId, workOrderNumber, notes, ...hours } = newData
    if (
      jobId === 'a95266a94a12aef844f7f81d449dfbb8' &&
      !(!!notes || !!workOrderNumber)
    ) {
      $message('Error: Work Order # or Notes required')
      return false
    }
    const validHours = !!Object.values(hours).filter(hour => !!+hour).length
    const valid = !!jobId && !!employeeId && validHours
    if (!valid) $message('Error: Job, Employee, and Hours required')
    return valid
  }
  //EDIT FUNCTIONS
  const editable = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
        const valid = isValid(newData)
        if (!valid) reject()
        if (valid) {
          dp(form_actions.time([...list, newData]))
          $message('Added')
          resolve()
        }
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        const oldId = oldData.tableData.id
        const valid = isValid(newData)
        if (!list[oldId] || !valid) reject()
        if (valid) {
          const updated = list.map((item, i) => (i === oldId ? newData : item))
          dp(form_actions.time(updated))
          $message('Updated')
          resolve()
        }
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        const updated = list.filter((item, i) => i !== list.indexOf(oldData))
        dp(form_actions.time(updated))
        $message('Deleted')
        resolve()
      })
  }

  //API CALLS
  const employees = useLookup({ collection: 'employees' })
  const jobs = useLookup({ collection: 'jobs' })

  useEffect(() => {
    if (!!employees && !!jobs) {
      const updatedColumns = cols
        .map(columnMap('employeeId', employees))
        .map(columnMap('jobId', jobs))
      $cols(updatedColumns)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees, jobs])

  return (
    <>
      <Picker value={week.selected} onChange={value => updateWeek(value)} />
      <Drawer>
        <Totals />
      </Drawer>
      <MaterialTable
        icons={icons}
        options={options}
        title='Time Form'
        columns={cols}
        data={list}
        actions={actions}
        editable={editable}
        components={{ Toolbar }}
      />
    </>
  )
}

export { Table }
