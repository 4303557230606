import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
//LOCAL
import { useAllStyles } from 'ln_style'
import { form_actions } from 'ln_core/rootReducer'
import { Controls, Details, Owner, Timeline, Setup } from './components'
import { NotesField, PageWrapper, AddressField, SectionHeader, CustomSnackbar } from 'ln_ui'

const Job = () => {
  const c = useAllStyles()
  const dp = useDispatch()
  const [message, $message] = useState('')
  const formData = useSelector(({ forms }) => forms.job)

  const update = (type, value) => {
    const output = { ...formData, [type]: value }
    dp(form_actions.job(output))
  }

  const props = key => ({
    className: c.formField,
    value: formData[key],
    onChange: value => update(key, value)
  })

  return (
    <PageWrapper paper title='New Job'>
      <CustomSnackbar {...{ message, $message }} />
      {SectionHeader('Details')}
      <Details {...props('details')} />
      {SectionHeader('Project Address')}
      <AddressField required {...props('address')} />
      {SectionHeader('Owner Information')}
      <Owner {...props('owner')} />
      {SectionHeader('Estimated Timeline')}
      <Timeline {...props('timeline')} />
      {SectionHeader('Check All That Apply')}
      <Setup {...props('setup')} />
      <NotesField
        section
        className={c.formField}
        value={formData.meta.notes}
        onChange={value => update('meta', { notes: value })}
      />
      <Controls {...{ $message }} />
    </PageWrapper>
  )
}

export default Job
