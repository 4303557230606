import React from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
//LOCAL
import { FormButtons } from 'ln_ui'
import { useAllStyles } from 'ln_style'
import { genCall, stamp, isFilled } from 'ln_util/functions'
import { form_actions } from 'ln_core/rootReducer'

const Controls = ({ $message }) => {
  const c = useAllStyles()
  const dp = useDispatch()
  const formData = useSelector(({ forms }) => forms.customer)
  const { email } = useSelector(({ hub }) => hub.user)

  const validCustomers = !!formData.customer
  const validAddress = isFilled(formData.address)
  const validPrimary = Object.entries(formData.primary).every(
    ([key, value]) => ['phone'].includes(key) || !!value
  )
  const valid = validCustomers && validAddress && validPrimary

  const submit = () => {
    const [url, opts] = genCall('new/customer', 'JSON')
    const { address, ...rest } = formData
    const data = {
      ...rest,
      shippingAddress: address,
      billingAddress: address,
      ...stamp(email)
    }
    axios
      .post(url, data, opts)
      .then(({ data }) => {
        $message(data)
        dp(form_actions.customer_clear())
      })
      .catch(console.log)
  }

  return (
    <FormButtons
      className={c.formField}
      submit={submit}
      slice='customer'
      valid={valid}
    />
  )
}

export { Controls }
