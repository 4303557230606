import React from 'react'
import { Snackbar, IconButton, Tooltip } from '@material-ui/core'
import { Close } from '@material-ui/icons'
// LOCAL
import { useAllStyles } from 'ln_style'

const localStyle = theme => ({ close: { padding: theme.spacing(0.5) } })

const CustomSnackbar = ({
  message,
  $message,
  autoHide = false,
  clear = false
}) => {
  const c = useAllStyles(localStyle)
  const handleClose = () => {
    $message('')
    if (clear) clear()
  }
  const closeBtn = (
    <Tooltip title='Dismiss'>
      <IconButton
        key='close'
        aria-label='Close'
        color='inherit'
        className={c.close}
        onClick={handleClose}
      >
        <Close />
      </IconButton>
    </Tooltip>
  )

  const defaultProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    open: !!message,
    onClose: handleClose,
    ContentProps: {
      'aria-describedby': 'message-id'
    },
    message: <span id='message-id'>{message}</span>,
    action: [closeBtn]
  }
  const autoHideDuration = 5000
  const props = autoHide ? { ...defaultProps, autoHideDuration } : defaultProps

  return <Snackbar {...{ ...props }} />
}

export default CustomSnackbar
