import { makeList } from 'ln_util/functions'

export const opts = {
         empty: [],
         material_category: ['Interior', 'Exterior'].map(makeList),
         salesman: ['DG', 'NL', 'TK', 'TR'].map(makeList),
         time_category: ['RT', 'OT'].map(makeList),
         boolean: ['true', 'false'].map(makeList),
         payroll_type: ['Hourly', 'Salary'].map(makeList),
         employee_category: [
           'Assistant',
           'Designer',
           'Fitter',
           'Helper',
           'Technician'
         ].map(makeList),
         compesation_class: ['5183', '7600', '8742', '8810'].map(makeList),
         role: ['default', 'admin', 'super'].map(makeList),
         supplier: [
           'ACT',
           'ADI',
           'Core & Main',
           'Sunstate',
           'Texas Manufacturing',
           'Viking SupplyNet',
           'United Rentals',
           'Redco'
         ].map(makeList),
         document_types: [
           { label: 'Contract', value: 'contract' },
           { label: 'Proposal', value: 'proposal' },
           { label: 'COI', value: 'coi' },
           { label: 'SOV', value: 'sov' },
           { label: 'Tax Resale Certification', value: 'taxExempt' },
           { label: 'Bond Information', value: 'publicJob' },
           { label: 'Supporting Document', value: 'other' }
         ],
         inspection: [
           'BFI',
           'FAI',
           'FDC',
           'FDI',
           'FEI',
           'FHI',
           'FPI',
           'FSI',
           'FSI_3',
           'FSI_5',
           'SDST',
           'TI',
           'VHI'
         ].map(makeList),
         frequency: [
           '1 every year',
           '2 every year',
           '4 every year',
           '1 every 2 years',
           '1 every 3 years',
           '1 every 5 years'
         ].map(makeList),
         state: [
           'Alaska',
           'Alabama',
           'Arkansas',
           'American Samoa',
           'Arizona',
           'California',
           'Colorado',
           'Connecticut',
           'District of Columbia',
           'Delaware',
           'Florida',
           'Georgia',
           'Guam',
           'Hawaii',
           'Iowa',
           'Idaho',
           'Illinois',
           'Indiana',
           'Kansas',
           'Kentucky',
           'Louisiana',
           'Massachusetts',
           'Maryland',
           'Maine',
           'Michigan',
           'Minnesota',
           'Missouri',
           'Mississippi',
           'Montana',
           'North Carolina',
           'North Dakota',
           'Nebraska',
           'New Hampshire',
           'New Jersey',
           'New Mexico',
           'Nevada',
           'New York',
           'Ohio',
           'Oklahoma',
           'Oregon',
           'Pennsylvania',
           'Puerto Rico',
           'Rhode Island',
           'South Carolina',
           'South Dakota',
           'Tennessee',
           'Texas',
           'Utah',
           'Virginia',
           'Virgin Islands',
           'Vermont',
           'Washington',
           'Wisconsin',
           'West Virginia',
           'Wyoming'
         ].map(makeList)
       }

export const dowArr = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]
