import React, { useState } from 'react'
import { Button } from '@material-ui/core'
// LOCAL
import { useSafety } from 'ln_util/hooks'
import { useAllStyles } from 'ln_style'
import { form_actions } from 'ln_core/rootReducer'
import { InfoDrawer } from './infoDrawer'
import { CustomSnackbar } from './'

const FormButtons = ({ submit, slice, children, valid, ...rest }) => {
  const { mr2 } = useAllStyles()
  const [message, $message] = useState('')
  const [toggle, safe, r_safe] = useSafety(form_actions[`${slice}_clear`])

  return (
    <div {... rest}>
      <CustomSnackbar {...{ message, $message }} />
      {!!children && <InfoDrawer disabled={!valid}>{children}</InfoDrawer>}
      <Button
        tabIndex={-1}
        variant='contained'
        className={mr2}
        color={safe ? 'secondary' : 'default'}
        onClick={() => {
          toggle()
          safe && $message('Form Cleared')
        }}
        onMouseOut={r_safe}>
        Clear{safe && '?'}
      </Button>
      <Button
        disabled={!valid}
        variant='outlined'
        color='secondary'
        onClick={submit}>
        Submit
      </Button>
    </div>
  )
}

export default FormButtons
