import React from 'react'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { isValid } from 'date-fns'

const DateField = ({ onChange, value, ...props }) => {
  const handleChange = value => {
    const date = new Date(value).setHours(0, 0, 0)
    isValid(date) && onChange(new Date(date).toISOString())
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        value={value}
        onChange={handleChange}
        autoOk
        format='MM/dd/yyyy'
        margin='none'
        emptyLabel=' '
        InputAdornmentProps={{ style: { padding: 0 } }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
          style: { padding: 0 }
        }}
        showTodayButton
      />
    </MuiPickersUtilsProvider>
  )
}

export { DateField }
