import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'
//LOCAL
import { useAllStyles } from 'ln_style'

const localStyles = ({ spacing }) => ({
  wrapper: { overflow: 'auto', width: 'fit-content', margin: spacing(4) }
})

const mapTableRow = ({ hid, name, total }) => (
  <TableRow key={hid} align='center'>
    <TableCell align='center'>{name}</TableCell>
    <TableCell align='center'>{total}</TableCell>
  </TableRow>
)

const headerObj = {
  hid: 'titles',
  name: 'Employee',
  total: 'Total'
}

const Totals = () => {
  const c = useAllStyles(localStyles)
  const [mapped, $mapped] = useState([])
  /** time
   * @params {Object[]} time - single time entry
   * @param {string} time.jobId
   * @param {string} time.employeeId
   * @param {string} time.notes
   * @param {string} time.monday
   * @param {string} time.tuesday
   * @param {string} time.wednesday
   * @param {string} time.thrusday
   * @param {string} time.friday
   * @param {string} time.saturday
   * @param {string} time.sunday
   * @param {Object} time.tableData
   */
  const time = useSelector(({ forms }) => forms.time)
  const { employees } = useSelector(({ lookups }) => lookups)

  /**
   * @return {[hid, employeeName, total]}
   */
  const mapTotals = () => {
    if (!!time.length && !mapped.length) {
      const employeeReducer = (
        ac,
        { jobId, employeeId, notes, tableData, workOrderNumber, ...days },
        i
      ) => {
        const total = Object.values(days)
          .map(l => +l)
          .reduce((a, c) => a + c, 0)
        ac[employeeId] =
          ac[employeeId] === undefined ? total : ac[employeeId] + total
        return ac
      }
      const employeeObj = time.reduce(employeeReducer, {})
      const output = Object.entries(employeeObj).map(([hid, total]) => ({
        name: employees[hid],
        hid,
        total
      }))
      $mapped(output)
    }
  }

  useEffect(mapTotals, [time])

  return (
    <Table classes={{ root: c.wrapper }}>
      <TableHead>{mapTableRow(headerObj)}</TableHead>
      <TableBody>{mapped.map(mapTableRow)}</TableBody>
    </Table>
  )
}

export { Totals }
