import React, { useState } from 'react'
//LOCAL
import { Table } from './table'
import { PageWrapper, CustomSnackbar } from 'ln_ui'

const Time = () => {
  const [message, $message] = useState('')

  return (
    <PageWrapper>
      <CustomSnackbar autoHide {...{ message, $message }} />
      <Table {...{ message, $message }} />
    </PageWrapper>
  )
}

export default Time
