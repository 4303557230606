import React, { useState } from 'react'
//LOCAL
import { PageWrapper, CustomSnackbar } from 'ln_ui'
import { Table } from './components'

const Jobs = ({ location, history }) => {
  const [message, $message] = useState('')

  return (
    <PageWrapper>
      <CustomSnackbar autoHide {...{ message, $message }} />
      <Table {...{ $message, location, history }} />
    </PageWrapper>
  )
}
export default Jobs
