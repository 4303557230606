import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  DialogTitle
} from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
//LOCAL
import { useAllStyles } from 'ln_style'

const localStyles = ({ spacing }) => ({
  table: {
    overflow: 'auto',
    width: 'fit-content',
    minWidth: spacing(30),
    margin: spacing(4)
  },
  name: { minWidth: spacing(20) },
  taken: { minWidth: spacing(10) }
})

const headerObj = {
  hid: 'titles',
  name: 'Employee',
  taken: 'Taken'
  // remaining: 'Remaining'
}

const Vacation = () => {
  const c = useAllStyles(localStyles)
  const [mapped, $mapped] = useState([])
  const [range, $range] = useState('year')
  const time = useSelector(({ dashboards }) => dashboards.time)
  const { employees } = useSelector(({ lookups }) => lookups)

  const mapTableRow = ({ hid, name, taken, remaining }) => (
    <TableRow key={hid} align='center'>
      <TableCell align='center' className={c.name}>
        {name}
      </TableCell>
      <TableCell align='center' className={c.taken}>
        {taken}
      </TableCell>
      {/* <TableCell align='center'>{remaining}</TableCell> */}
    </TableRow>
  )

  const mapTotals = () => {
    if (!!time.length) {
      const employeeReducer = (ac, { jobId, employeeId, hours, date }, i) => {
        const isThisYear =
          new Date(date).getFullYear() === new Date().getFullYear()
        const isVacation = jobId === '73f3502399887a3b3189fa0e7e803441'
        const isInRange = range === 'all' || isThisYear
        if (isVacation && isInRange) {
          ac[employeeId] =
            ac[employeeId] === undefined ? +hours : ac[employeeId] + +hours
        }
        return ac
      }
      const employeeObj = time.reduce(employeeReducer, {})
      const output = Object.entries(employeeObj)
        .map(([hid, taken]) => ({
          name: employees[hid],
          hid,
          taken: `${Math.floor(taken / 8)}d${taken % 8}h`
        }))
        .filter(({ name }) => !!name)
      $mapped(output)
    }
  }

  useEffect(mapTotals, [time, range])

  return (
    <div className={c.center}>
      <DialogTitle>Paid Time Off</DialogTitle>
      <ToggleButtonGroup
        size='small'
        value={range}
        exclusive
        onChange={(ev, val) => $range(val)}
      >
        <ToggleButton value='all'>All Time</ToggleButton>
        <ToggleButton value='year'>This Year</ToggleButton>
      </ToggleButtonGroup>
      <Table classes={{ root: c.table }}>
        <TableHead>{mapTableRow(headerObj)}</TableHead>
        <TableBody>{mapped.map(mapTableRow)}</TableBody>
      </Table>
    </div>
  )
}

export { Vacation }
