import React, { useState } from 'react'
import { Paper } from '@material-ui/core'
import { TreeView } from '@material-ui/lab'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { Link, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
//LOCAL
import { useAllStyles } from 'ln_style'
import routes from 'ln_util/routes'
import { StyledTreeItem } from './styledTreeItem'

const drawerWidth = 240
export const localStyle = ({
  breakpoints: { down },
  spacing,
  transitions: {
    create,
    easing: { easeOut, sharp },
    duration: { leavingScreen, enteringScreen }
  }
}) => {
  const showDrawer = create(['width', 'margin'], {
    easing: easeOut,
    duration: enteringScreen
  })

  const hideDrawer = create(['width', 'margin'], {
    easing: sharp,
    duration: leavingScreen
  })

  return {
    showDrawer: {
      height: 'fit-content',
      width: drawerWidth,
      minWidth: drawerWidth,
      margin: spacing(2),
      transition: hideDrawer,
      [down('sm')]: {
        // margin: spacing(1, 0),
        margin: spacing(1)
      }
    },
    hideDrawer: {
      height: 'fit-content',
      width: 0,
      minWidth: 0,
      margin: spacing(2, 0),
      overflow: 'hidden',
      transition: showDrawer
    },
    drawerItem: {
      minWidth: 'max-content'
    },
    content: {
      width: `calc(100vw - ${drawerWidth}px - ${spacing(8)}px)`,
      maxWidth: `calc(100vw - ${drawerWidth}px - ${spacing(8)}px)`,
      margin: spacing(2),
      transition: hideDrawer
    },
    contentShift: {
      width: `calc(100vw - ${spacing(4)}px)`,
      maxWidth: `calc(100vw - ${spacing(4)}px)`,
      margin: spacing(2),
      marginLeft: 0,
      transition: showDrawer
    },
    tree: { margin: spacing(2), flexGrow: 1, maxWidth: 400 }
  }
}

const RootDrawer = ({ location: { pathname } }) => {
  const c = useAllStyles(localStyle)
  const {
    menu,
    user: { view }
  } = useSelector(({ hub }) => hub)
  const [expanded, setExpanded] = useState(['1', '2'])
  const handleChange = (event, nodes) => setExpanded(nodes)

  return (
    <Paper className={menu ? c.showDrawer : c.hideDrawer}>
      <TreeView
        className={c.tree}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        expanded={expanded}
        onNodeToggle={handleChange}
      >
        <StyledTreeItem nodeId='1' labelText='Forms'>
          {routes
            .filter(({ noMenu, section }) => !noMenu && section === 'Forms')
            .filter(({ path }) => view.includes(path))
            .map(({ text, path, icon, nodeId }) => {
              return (
                <Link to={path} key={text}>
                  <StyledTreeItem
                    key={text}
                    nodeId={nodeId}
                    labelText={text}
                    labelIcon={icon}
                  />
                </Link>
              )
            })}
        </StyledTreeItem>
        <StyledTreeItem nodeId='2' labelText='Dashboards'>
          {routes
            .filter(
              ({ noMenu, section }) => !noMenu && section === 'Dashboards'
            )
            .map(({ text, path, icon, nodeId }) => {
              return (
                <Link to={path} key={text}>
                  <StyledTreeItem
                    key={text}
                    nodeId={nodeId}
                    labelText={text}
                    labelIcon={icon}
                  />
                </Link>
              )
            })}
        </StyledTreeItem>
      </TreeView>
    </Paper>
  )
}

export default withRouter(RootDrawer)
