import React from 'react'
//LOCAL
import { PageWrapper } from 'ln_ui'
import { Table } from './components'

const PurchaseOrders = () => {

  return (
    <PageWrapper>
      <Table />
    </PageWrapper>
  )
}
export default PurchaseOrders
