import React from 'react'
//LOCAL
import { useAllStyles } from 'ln_style'
import { APISelect, CurrencyField, ListSelect, TextField } from 'ln_ui'

const Details = ({ value, onChange }) => {
  const c = useAllStyles()

  const update = (k, v) => {
    const output = { ...value, [k]: v }
    onChange(output)
  }

  const props = key => ({
    className: c.formField,
    value: value[key],
    onChange: value => update(key, value)
  })

  return (
    <>
      <TextField autoFocus required label='Job Name' {...props('name')} />
      <APISelect
        required
        withLabel
        collection='customers'
        {...props('customer')}
      />
      <ListSelect
        required
        withLabel
        list='salesman'
        {...props('salesman')}
      />
      <CurrencyField label='Contract Amount' {...props('amount')} />
    </>
  )
}

export { Details }
