import React, { useState } from 'react'
import { Drawer as MatDrawer } from '@material-ui/core'

const useDrawer = () => {
  const [open, $open] = useState(false)
  const openDrawer = () => $open(true)
  const closeDrawer = () => $open(false)
  const Drawer = ({ children, anchor = 'right', ...rest }) => (
    <MatDrawer anchor={anchor} open={open} onClose={closeDrawer} {...rest}>
      {children}
    </MatDrawer>
  )

  return [openDrawer, Drawer]
}

export { useDrawer }
