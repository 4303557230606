import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

// DONT DESTRUCTURE LOCATION, ROUTE NEEDS IT IN PROPS
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector(({ hub }) => hub)
  return (
    <Route
      {...rest}
      exact={true}
      render={props => {
        const hasPerms = user.view.includes(props.location.pathname)
        const authed = hasPerms || props.location.pathname === '/'
        return authed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }}
    />
  )
}

export default PrivateRoute
