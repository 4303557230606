import { dateSort, humanDate } from 'ln_util/functions'

const columns = [
  {
    field: 'date',
    title: 'Date',
    defaultSort: 'desc',
    customSort: dateSort,
    render: rowData =>
      !!rowData.date ? humanDate(rowData.date) : humanDate(rowData)
  },
  {
    field: 'jobId',
    title: 'Job'
  },
  {
    field: 'employeeId',
    title: 'Employee',
    defaultSort: 'asc'
  },
  {
    field: 'hours',
    title: 'Hours'
  },
  {
    field: 'category',
    title: 'Category'
  },
  {
    field: 'notes',
    title: 'Notes'
  },
  {
    field: 'certPay',
    title: 'Wage Scaled',
    lookup: { [true]: 'Yes', [false]: 'No' }
  }
]

export { columns }