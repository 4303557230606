import React from 'react'
import { Typography, Icon } from '@material-ui/core'
import { TreeItem } from '@material-ui/lab'
//LOCAL
import { useAllStyles } from 'ln_style'
import { cladd } from 'ln_util/functions'

const localStyles = ({
  palette: {
    primary: { main },
    text: { secondary }
  },
  spacing,
  typography: { fontWeightMedium, fontWeightRegular }
}) => ({
  root: {
    color: secondary,
    '&:focus > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${main})`,
      color: 'white'
    }
  },
  content: {
    color: secondary,
    borderTopRightRadius: spacing(2),
    borderBottomRightRadius: spacing(2),
    paddingRight: spacing(1),
    fontWeight: fontWeightMedium,
    '$expanded > &': {
      fontWeight: fontWeightRegular
    }
  },
  group: {
    marginLeft: 0,
    paddingLeft: spacing(2)
  },
  expanded: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0.5, 0)
  },
  labelIcon: {
    margin: spacing(0,1),
    fontSize: spacing(2),
    overflow: 'visible'
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1
  }
})

const StyledTreeItem = ({
  labelText,
  labelIcon,
  labelInfo,
  color,
  bgColor,
  ...other
}) => {
  const c = useAllStyles(localStyles)

  return (
    <TreeItem
      label={
        <div className={c.labelRoot}>
          {labelIcon && <Icon className={cladd(`fas fa-${labelIcon}`, c.labelIcon)} />}
          <Typography variant='body2' className={c.labelText}>
            {labelText}
          </Typography>
          <Typography variant='caption' color='inherit'>
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor
      }}
      classes={{
        root: c.root,
        content: c.content,
        expanded: c.expanded,
        group: c.group,
        label: c.label
      }}
      {...other}
    />
  )
}

export { StyledTreeItem }
