import React, { useState } from 'react'
//LOCAL
import { PageWrapper, CustomSnackbar } from 'ln_ui'
import Table from './table'

const Times = props => {
  const [message, $message] = useState('')

  return (
    <PageWrapper>
      <CustomSnackbar autoHide {...{ message, $message }} />
      <Table {...{ $message }} />
    </PageWrapper>
  )
}
export default Times
