import React from 'react'
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Card,
  CardHeader
} from '@material-ui/core'
//LOCAL
import { useAllStyles } from 'ln_style'
import { CurrencyField, TextField, FileUpload } from 'ln_ui'

const Setup = ({ value, onChange }) => {
  const c = useAllStyles()

  const update = (k, v) => {
    const output = { ...value, [k]: v }
    onChange(output)
  }

  const props = key => ({
    className: c.ml1,
    checked: value[key],
    onChange: () => update(key, !value[key])
  })

  const embolden = text =>
    text.split('~').map((str, i) => (i % 2 ? <b key={i}>{str}</b> : str))

  return (
    <div>
      <FormGroup className={c.formField}>
        <FormControlLabel
          {...props('contract')}
          control={<Checkbox />}
          label={embolden('~Contract~ needs to be saved in job folder.')}
        />
        <FormControlLabel
          {...props('sov')}
          control={<Checkbox />}
          label={embolden('~SOV~ needs to be saved in job folder.')}
        />
        <FormControlLabel
          {...props('proposal')}
          control={<Checkbox />}
          label={embolden('~Proposal~ needs to be saved in job folder.')}
        />
        <FormControlLabel
          {...props('taxExempt')}
          control={<Checkbox />}
          label={embolden('This is a ~tax exempt~ job.')}
        />
        <FormControlLabel
          {...props('publicJob')}
          control={<Checkbox />}
          label={embolden('This is a ~public~ job.')}
        />
        <FormControlLabel
          {...props('wageScale')}
          control={<Checkbox />}
          label={embolden('This is a ~wage scaled~ job.')}
        />
        {!!value.wageScale && (
          <Grid container>
            <Grid item xs={12} md={6}>
              <CurrencyField
                autoFocus
                className={c.formField}
                label="Helper's Wage"
                value={value.helperWage}
                onChange={value => update('helperWage', value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CurrencyField
                className={c.formField}
                label="Fitter's Wage"
                value={value.fitterWage}
                onChange={value => update('fitterWage', value)}
              />
            </Grid>
          </Grid>
        )}
        <FormControlLabel
          {...props('coi')}
          control={<Checkbox />}
          label={embolden('A ~COI~ needs to be requested.')}
        />
      </FormGroup>
      {!!value.coi && (
        <Card raised className={c.formField}>
          <CardHeader title='COI Request' />
          <FormControlLabel
            {...props('jobSpecificCoi')}
            control={<Checkbox />}
            label={'Job Specific'}
          />
          <TextField
            autoFocus
            variant='outlined'
            label='Message (optional)'
            multiline
            rowsMax={4}
            className={c.formField}
            value={value.coiMsg}
            onChange={value => update('coiMsg', value)}
          />
          <FileUpload
            label='Add Attachments'
            icon='paperclip'
            value={value.coiAttachments}
            onChange={value => update('coiAttachments', value)}
            single
          />
        </Card>
      )}
    </div>
  )
}

export { Setup }
