import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dialog } from '@material-ui/core'
//LOCAL
import { form_actions } from 'ln_core/rootReducer'
import { PageWrapper, CustomSnackbar } from 'ln_ui'
import { Form, Table } from './components'

const Inspection = props => {
  const dp = useDispatch()
  const [message, $message] = useState('')
  const [form, $form] = React.useState([])
  const clearForm = () => {
    dp(form_actions.inspection_clear())
    $form([])
  }


  return (
    <PageWrapper>
      <CustomSnackbar autoHide {...{ message, $message }} />
      <Dialog open={!!form.length} onClose={clearForm}>
        <Form {...{ form, $form, $message }} />
      </Dialog>
      <Table {...{ $message, $form }} />
    </PageWrapper>
  )
}

export default Inspection
