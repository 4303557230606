import React from 'react'
import { TextField } from '@material-ui/core'
// LOCAL
import { isNumber } from 'ln_util/functions'

const ZipField = ({ onChange, value, ...props }) => {

  return (
    <TextField
      {...props}
      fullWidth
      value={value}
      onChange={({ target: { value } }) => {
        const [dest, route] = value.split('-')
        const destGood = isNumber([dest, route].join('')) && dest.length <= 5
        const routeGood = !!route ? route.length < 5 : true
        if (destGood && routeGood) onChange(value)
      }}
      InputLabelProps={{
        shrink: true
      }}
    />
  )
}

export { ZipField }