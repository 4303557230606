import React, { useState } from 'react'
//LOCAL
import { DateField } from 'ln_ui'

const useDatePicker = () => {
  const [picker, $picker] = useState(false)
  const openPicker = () => $picker(true)
  const closePicker = () => $picker(false)
  const Picker = props => (
    <DateField
      {...props}
      open={picker}
      onOpen={openPicker}
      onClose={closePicker}
      style={{display: 'none'}}
    />
  )
  return [openPicker, Picker]
}

export { useDatePicker }
