import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, Checkbox } from '@material-ui/core'
//LOCAL
import { useAllStyles } from 'ln_style'
import { form_actions } from 'ln_core/rootReducer'
import { Controls } from './controls'
import { reduck } from 'ln_util/functions'
import {
  NotesField,
  CustomSnackbar,
  PageWrapper,
  AddressField,
  SectionHeader,
  TextField,
  Grid
} from 'ln_ui'

const Customer = props => {
  const c = useAllStyles()
  const dp = useDispatch()
  const [message, $message] = useState('')
  const formData = useSelector(({ forms }) => forms.customer)

  const update = (name, value) => {
    const output = reduck(formData, { name, value })
    dp(form_actions.customer(output))
  }

  const genProps = key => ({
    className: c.formField,
    value: formData[key],
    onChange: value => update(key, value)
  })

  return (
    <PageWrapper paper title='New Customer'>
      <CustomSnackbar autoHide {...{ message, $message }} />
      <TextField
        label='Customer Name'
        {...genProps('customer')}
        required
        autoFocus
      />
      {SectionHeader('Address')}
      <AddressField {...genProps('address')} required />
      {SectionHeader('Billing Contact')}
      <TextField label='Name' {...genProps('primary.name')} required />
      <Grid>
        <TextField label='Email' {...genProps('primary.email')} required />
        <TextField label='Phone' {...genProps('primary.phone')} />
      </Grid>
      {SectionHeader('Secondary Contact')}
      <FormControlLabel
        label='cc invoices to secondary contact'
        checked={formData.secondary.cc}
        onChange={() => update('secondary.cc', !formData.secondary.cc)}
        className={c.formField}
        control={<Checkbox />}
      />
      <TextField label='Name' {...genProps('secondary.name')} />
      <Grid>
        <TextField label='Email' {...genProps('secondary.email')} />

        <TextField label='Phone' {...genProps('secondary.phone')} />
      </Grid>
      <NotesField section {...genProps('notes')} />
      <Controls {...{ $message }} />
    </PageWrapper>
  )
}

export default Customer
