import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, Checkbox } from '@material-ui/core'
//LOCAL
import {
  PageWrapper,
  APISelect,
  ListSelect,
  TextField,
  CurrencyField,
  FormButtons,
  CustomSnackbar,
  NotesField,
  DateField
} from 'ln_ui'
import { genCall, stamp, reduck } from 'ln_util/functions'
import { form_actions } from 'ln_core/rootReducer'
import { useAllStyles } from 'ln_style'

const QuickJob = props => {
  const c = useAllStyles()
  const dp = useDispatch()
  const [message, $message] = useState('')
  const formData = useSelector(({ forms }) => forms.quickJob)
  const { email } = useSelector(({ hub }) => hub.user)
  const clear = () => dp(form_actions.quickJob_clear())
  const update = (name, value) => {
    const output = reduck(formData, { name, value })
    dp(form_actions.quickJob(output))
  }

  const genProps = key => {
    const [category, type] = key.split('.')
    const value = !!type ? formData[category][type] : formData[category]
    return {
      className: c.formField,
      value,
      onChange: value => update(key, value)
    }
  }

  const valid = Object.entries(formData.details).every(
    ([key, value]) => ['amount'].includes(key) || !!value
  )

  const submit = () => {
    const [url, opts] = genCall('new/quick-job', 'JSON')
    const data = { ...formData, ...stamp(email) }
    axios
      .post(url, data, opts)
      .then(({ data }) => $message(data))
      .catch(console.log)
  }

  return (
    <PageWrapper paper title='New Quick Job'>
      <CustomSnackbar {...{ message, $message, clear }} />
      <DateField label='Date of Service' {...genProps('timeline.date')} />
      <TextField label='Job Name' {...genProps('details.name')} required />
      <APISelect
        withLabel
        {...genProps('details.customer')}
        collection='customers'
        required
      />
      <ListSelect
        withLabel
        {...genProps('details.salesman')}
        list='salesman'
        required
      />
      <CurrencyField label='Amount' {...genProps('details.amount')} />
      <FormControlLabel
        className={c.formField}
        checked={formData.active}
        onChange={() => update('active', !formData.active)}
        control={<Checkbox />}
        label={'Keep job active to apply time and material to it.'}
      />
      <NotesField {...genProps('meta.notes')} />
      <FormButtons
        className={c.formField}
        submit={submit}
        slice='quickJob'
        valid={valid}
      />
    </PageWrapper>
  )
}

export default QuickJob
