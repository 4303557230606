import { useState, useEffect } from 'react'
//LOCAL
import { isEmpty, compareObjects } from 'ln_util/functions'

export const useLocalState = (parent, object = false) => {
  const [local, $local] = useState(parent)
  useEffect(() => {
    if (!!object) {
      const shouldUpdate =
        isEmpty(parent, 'object') || !compareObjects(local, parent)
      if (shouldUpdate) $local(parent)
    } else {
      const shouldUpdate = isEmpty(parent) || local !== parent
      if (shouldUpdate) $local(parent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent])

  return [local, $local]
}
