import React from 'react'
import axios from 'axios'
import objectToFormData from 'json-form-data'
import { useDispatch, useSelector } from 'react-redux'
//LOCAL
import { FormButtons } from 'ln_ui'
import { useAllStyles } from 'ln_style'
import { genCall, stamp, isFilled } from 'ln_util/functions'
import { form_actions } from 'ln_core/rootReducer'


const Controls = ({$message}) => {
  const c = useAllStyles()
  const dp = useDispatch()
  const formData = useSelector(({ forms }) => forms.job)
  const { email } = useSelector(({ hub }) => hub.user)

  const validDetails = Object.entries(formData.details).every(
    ([key, value]) => ['amount'].includes(key) || !!value
  )
  const validAddress = isFilled(formData.address)
  const validOwner = !!formData.owner.name && isFilled(formData.owner.address)
  const sections = [validDetails, validAddress, validOwner]
  const valid = sections.every(bool => bool)

  const submit = () => {
    const [url, opts] = genCall('new/job', 'form')
    const data = objectToFormData({ ...formData, ...stamp(email) })
    axios
      .post(url, data, opts)
      .then(({ data }) => {
        $message(data)
        dp(form_actions.job_clear())
      })
      .catch(console.log)
  }

  return (
    <FormButtons
      className={c.formField}
      submit={submit}
      slice='job'
      valid={valid}
    />
  )
}

export { Controls }
