import React from 'react'
//LOCAL
import { genEditComponent } from 'ln_ui/table'
import { APISelect, ListSelect } from 'ln_ui'
import { dateSort, humanDate } from 'ln_util/functions'

const columns = [
  {
    field: 'contractCustomer',
    title: 'Customer',
    editComponent: props => (
      <APISelect collection='contract_customers' {...props} />
    ),
    headerStyle: { width: 300 }
  },
  {
    field: 'iType',
    title: 'Inspection',
    editComponent: props => <ListSelect list='inspection' {...props} />
  },
  {
    field: 'fType',
    title: 'Frequency',
    editComponent: props => <ListSelect list='frequency' {...props} />,
    sorting: false
  },
  {
    field: 'date',
    title: 'Due Date',
    editComponent: genEditComponent('date'),
    defaultSort: 'asc',
    customSort: dateSort,
    initialEditValue: new Date(new Date().setHours(0, 0, 0)).toISOString(),
    render: rowData => <>{humanDate(rowData.date)}</>
  }
]

export { columns }