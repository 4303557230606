//COMPARE HELPERS
const unique = (ac, cu) => (ac.includes(cu) ? ac : [...ac, cu])
const allArrays = (...arrays) => arrays.every((arr, i) => Array.isArray(arr))
const sameLength = (...arrays) =>
  arrays.map(arr => arr.length).reduce(unique, []).length === 1
const sameElements = (...arrays) =>
  arrays.every((arr, i, curr) =>
    curr
      .filter((x, j) => j !== i)
      .every(other => other.every((ele, e) => arr[e] === ele))
  )
const compareArrays = (...arrays) =>
  allArrays(...arrays) && sameLength(...arrays) && sameElements(...arrays)

const compareObjects = (a, b) =>
  compareArrays(Object.values(a), Object.values(b)) &&
  compareArrays(Object.keys(a), Object.keys(b))

export { compareArrays, compareObjects }
